<template>
    <div>
        <v-img v-if="false" :aspect-ratio="16 / 9" :src="headerImage" min-height="250" gradient="to bottom left, rgba(0,0,0,.1), rgba(0,0,0,.7)">
            <v-row align="center" justify="center" class="lightbox white--text pa-4 fill-height">
                <v-col>
                    <h2 class="text-center text-h5">
                        {{ headerTitle }}
                    </h2>
                </v-col>
            </v-row>
        </v-img>
        <div>
            <v-container class="px-6" style="max-width: 600px;">
                <!-- <div class="property-details mt-3">
                    <div class="reservation-status text-subtitle-1">{{ btr("Reservation confirmed") }}</div>
                    <div class="property-name text-h6">{{ property.name }}</div>
                    <div class="property-address text-subtitle-2 mt-1">{{ property.address }} {{ property.city }}</div>
                </div> -->
                <reservation-visual :reservation="reservation" :entry="entry"></reservation-visual>
                <div class="checkin-data mt-2 pa-4" v-if="reservation && reservation.checkin">
                    <div class="d-flex">
                        <div class="reservation-date checkin px-4">
                            <div class="font-weight-600">Check in</div>
                            <div class="checkin-date">
                                {{ reservation.date_start_hr }}
                            </div>
                        </div>
                        <div class="reservation-date px-4">
                            <div class="font-weight-600">Check out</div>
                            <div class="checkin-date">
                                {{ reservation.date_end_hr }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(reservation.staying || reservation.checked_in) && reservation.canUnlock && entry.property && entry.property.locks" class="elevated-message mt-4 pa-4">
                    <v-progress-linear v-if="loading" :active="loading" color="primary" :indeterminate="true"></v-progress-linear>
                    <div v-for="(lock, ind) in entry.property.locks" :key="ind">
                        <div class="my-2 px-4 text-h6" v-if="!openRequested">{{ btr("Open") + " " + lock.name }}</div>
                        <drag-verify v-if="!openRequested" text="&rarr; &rarr; &rarr; &rarr; &rarr;" successText="Apertura in corso" :handler-icon="require('@/assets/img/icon-lock.svg')" :success-icon="require('@/assets/img/icon-unlock.svg')" handler-bg="#11A6AC" progressBarBg="#42929D" completedBg="#42929D" background="#ECEEF0" text-size="14px" :width="280" class="mx-auto my-4" @swiped="openLock(lock)"></drag-verify>
                    </div>
                    <div class="pt-4 px-2 grey--text" v-if="!openRequested">{{ btr("Drag the circle to the right to open") }}</div>
                    <div class="my-4" v-if="openRequested">
                        <div>
                            {{ btr("If the door is not unlocked, please wait a couple of seconds to try again.") }}
                        </div>
                        <div v-if="reservation.lockInstructions" v-html="reservation.lockInstructions.replace('\n', '<br/>')"></div>
                    </div>
                </div>
                <div v-if="!reservation.checked_in">
                    <div class="data-consolidation-prompt mt-4" v-if="!reservation.arrivalTime && !skipSurvey">
                        <div>{{ btr("I need some informations to prepare your arrival.") }}</div>
                        <v-btn to="/online-checkin" color="#11A6AC" dark depressed block class="mt-4 custom-transform-class text-none font-weight-700" x-large>{{
            btr("Let's prepare for your arrival")
        }}</v-btn>
                    </div>
                    <div v-if="reservation.arrivalTime" class="text-center mt-6">
                        <v-btn class="mt-0" color="primary" text rel="noreferrer" target="_blank" :href="'https://www.google.com/maps?f=q&source=s_q&q=' + this.reservation.property_address + ' ' + this.reservation.property_city">{{
            btr("Open address with google maps") }}</v-btn>
                    </div>
                    <div v-if="skipSurvey && !reservation.checkinDisabled && entry.canCheckin" class="text-center mt-6 mb-4">
                        <div v-if="entry.selfcheckins">
                            <p>
                                {{ this.btr("When you are ready to check-in please press the button below") }}
                            </p>
                            <v-btn block to="/checkin" depressed x-large color="primary" class="white--text">{{
            this.btr("Ok, check me in!") }}</v-btn>
                        </div>
                    </div>
                    <div v-else-if="reservation.arrivalTime" class="my-4 mx-4">
                        <div v-if="this.reservation.interval">
                            <!-- {{ btr("In %s days you can perform self check-in directly from this page.",
                                [this.reservation.interval.d]) }} -->
                            <v-btn block to="/try-checkin" depressed x-large color="primary" class="white--text">{{
            this.btr("Start check-in") }}</v-btn>
                        </div>
                        <div v-if="false && this.reservation.interval && this.reservation.interval.d == 0 && !this.reservation.checked_in">
                            {{ btr("Check-in will be available starting at") + " " + (this.entry.checkin_time ||
            this.entry.property.checkin_time) }}
                        </div>
                        <div v-if="false && this.reservation.interval && this.reservation.interval.d == 0 && !this.reservation.checked_in">
                            {{ btr("Please visit this page when you are ready and after") + " " + (this.entry.checkin_time
            || this.entry.property.checkin_time) }}
                        </div>
                    </div>
                </div>

                <div v-if="guides && guides.length" class="mt-8">
                    <p class="mb-4">
                        {{ btr("In the meantime, I've prepared some information that might be helpful to you") }}
                    </p>
                    <guides :guides="guides" :reservation_id="reservation.reservation_id"></guides>
                </div>
                <t-footer :reservation="reservation" class="mt-12"></t-footer>
            </v-container>
        </div>
    </div>
</template>
<script>
import dragVerify from "@/components/dragVerify";
import ReservationVisual from "@/components/ReservationVisual.vue";
import Footer from "@/components/Footer.vue";
import ReservationGuides from "@/components/ReservationGuides";
export default {
    components: {
        "reservation-visual": ReservationVisual,
        "t-footer": Footer,
        guides: ReservationGuides,
        "drag-verify": dragVerify,
    },
    props: ["reservation", "entry"],
    data() {
        return {
            skipSurvey: false,
            step: 1,
            guides: null,
            headerImage: null,
            headerTitle: null,
            property: null,
            secondaryImages: [],
            rData: { countryCode: "" },
            countryCodes: [],
            openRequested: false,
            loading: false,
        };
    },
    computed: {
        source() {
            return this.reservation.source || null;
        },
        vendor() {
            return this.getVendor(true);
        },
    },
    created() {
        this.getCountryCodes();
        this.headerImage = this.entry.cover_img || null;
        this.headerTitle = this.entry.header_content || null;
        this.property = this.entry.property;
        // if (this.property && this.property.listing) {
        //     this.secondaryImages.push(this.property.listing.photos[1]);
        //     if (!this.vendor) {
        //         this.secondaryImages.push(this.property.listing.photos[2]);
        //     }
        // }
        this.setSkipSurvey();
        console.log(this.reservation);
        this.api.get("getguides/" + this.reservation.reservation_id + "/a3915eb049f3f438?json=1&lang=" + (localStorage.btrLang || 0)).then((res) => {
            this.guides = res.guides;
            console.log(this.guides);
        });
    },
    watch: {
        reservation() {
            this.setSkipSurvey();
        },
    },
    methods: {
        openLock(lock) {
            console.log("sending open input..." + this.reservation.access_pin);
            setTimeout(() => {
                const data = { access_pin: this.reservation.access_pin, unlock: 1, lockId: lock.key };
                this.api.post("openSmartLock/" + this.reservation.reservation_id, { data: data }).then((res) => {
                    console.log(res);
                    setTimeout(() => {
                        this.openRequested = true;
                        this.loading = false;
                        setTimeout(() => {
                            this.openRequested = false;
                        }, 1000 * 10);
                    }, 1000);
                });
            }, 2000);
            this.loading = true;
        },
        setSkipSurvey() {
            const r = this.reservation;
            if (r) {
                const datetimestr = r.checkin + "T" + (r.checkin_time || this.entry.property.checkin_time || "16:00 CET");
                console.log("[SKIPSURVEY]", datetimestr, "skip survey if you can check-in based on the checkin_time");
                const d = new Date(datetimestr);
                const diff = Date.now() - d.getTime();
                this.skipSurvey = diff > 0;
            }
        },
        getCountryCodes() {
            return;
            this.api.get("hostfiles/countries.json").then((res) => {
                this.countryCodes = res;
            });
        },
        getGuestName() {
            if (this.reservation.guest_name.indexOf("Guest") !== -1) {
                return null;
            } else {
                let name = this.reservation.guest_name.split(" ");
                if (name[0].length < 3 && name.length > 1) {
                    return name[0] + " " + name[1];
                }
                return name[0];
            }
        },
        getVendor(key = false) {
            const supported = { airbnb: "Airbnb", booking: "Booking", tomokit: "Tomokit" };
            if (this.reservation.vendor) {
                this.reservation.vendor = this.reservation.vendor == "manual" ? "tomokit" : this.reservation.vendor;
                if (key) {
                    return supported[this.reservation.vendor] ? this.reservation.vendor : null;
                }
                return supported[this.reservation.vendor] || " ";
            } else {
                if (key) {
                    return null;
                }
                return " ";
            }
        },
    },
};
</script>
<style></style>
