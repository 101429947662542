<template>
    <div>
        <v-toolbar elevation="0" class="px-4 chatbar">
            <div v-if="others">
                {{ btr("Chat with ") }} <span>{{ others.join(", ") }}</span>
            </div>
            <div v-else>
                {{ btr("Chat with ") }} <span>{{ subject_id }}</span>
            </div>
        </v-toolbar>
        <div class="chat">
            <div class="chat__header d-flex" v-if="false">
                <div class="chat__header__greetings" v-if="users">
                    {{ btr("Chat with ") }} <span>{{ others.join(", ") }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn elevation="0" @click="updateMessages" color="primary" x-small>{{ btr("update") }}</v-btn>
            </div>

            <chat-list :msgs="msgData" :isadmin="isadmin"></chat-list>
            <chat-form @submitMessage="sendMessage2" :loading="loading"></chat-form>
        </div>
    </div>
</template>

<script>
import ChatList from "../components/ChatList";
import ChatForm from "../components/ChatForm";
import Constant from "../Constant";

export default {
    props: { userData: { type: Object }, subject: { default: "reservation" }, id: {}, isadmin: {}, entry: {}, scrollable: {}, isChatAgent: {default: false} },
    data() {
        return {
            others: null,
            msgData: [],
            users: null,
            user: { name: "Antonio", isAdmin: true },
            timeout: null,
            subject_id: null,
            timeToUpdate: 5000,
            loading: false,
        };
    },
    components: {
        ChatList,
        ChatForm,
    },
    computed: {},
    created() {
        this.userData = this.$route.params.userData;
    },

    mounted() {
        this.user.isAdmin = this.isadmin;
        if (!this.isadmin && this.entry) {
            this.others = [this.entry.property.primaryHost.first_name];
        }
        this.subject_id = this.$route.params.subject_id || this.id;
        if (this.subject_id) {
            this.updateMessages(this.scrollDown);
        }
        // this.$socket.on("chat", (data) => {
        //     this.pushMsgData(data);

        //     setTimeout(() => {
        //         const element = document.getElementById("chat__body");
        //         element.scrollTop = element.scrollHeight;
        //     }, 0);
        // });
    },
    destroyed() {
        clearTimeout(this.timeout);
        this.timeout = null;
    },
    watch: {
        entry() {
            if (!this.isadmin && this.entry) {
                this.others = [this.entry.property.primaryHost.first_name];
            }
        },
        id(id) {
            if (id) {
                this.subject_id = id;
                this.msgData = [];
                this.users = null;
                this.others = null;
                this.updateMessages(this.scrollDown);
            } else {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
            }
        },
    },
    methods: {
        scrollDown() {
            console.log("start scroll");
            this.$nextTick(() => {
                if (this.scrollable) {
                    var objDiv = document.querySelector(this.scrollable);
                    var to = objDiv.scrollHeight;
                    console.log("scrolling", objDiv, to);
                    if (objDiv) {
                        objDiv.scrollTop = to;
                        console.log("scrolled");
                    }
                }
            });
        },
        sendMessage2(msg) {
            if (!msg.trim()) {
                return;
            }
            this.loading = true;
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            const data = {
                tread_id: "",
                is_hosting: this.isadmin,
                message_body: msg,
                isJson: true,
            };
            if (!this.isadmin) {
                data.info = { route: this.$route.fullPath };
                if (this.$store.state.currentiInfo) {
                    data.info = Object.assign(data.info, this.$store.state.currentiInfo);
                }
                data.info = JSON.stringify(data.info);
            }
            data.isChatAgent = this.isChatAgent;
            data[this.subject] = this.subject_id;
            let url = "hosting/messages/post";
            if (!this.isadmin) {
                url = "messages/post";
            }
            this.api.post(url, { data: data }).then((res) => {
                this.loading = false;
                if (res.success) {
                    console.log(this.msgData, typeof this.msgData);
                    console.log("pushing answer");
                    this.msgData.push(res.message);
                    if (res.me) {
                        this.$store.state.me = res.me;
                    }
                    this.scrollDown();
                }
                if (!this.timeout) {
                    this.timeout = setTimeout(() => {
                        this.updateMessages(this.scrollDown);
                    }, this.timeToUpdate);
                }
            });
        },
        updateMessages(fn = null) {
            this.timeout = null;
            this.loading = false;            
            let url = "get_new_messages/" + this.subject_id + "/";
            if (this.msgData.length) {
                url += this.msgData[this.msgData.length - 1]._id.$oid;
            }
            if (this.isChatAgent) {
                url += "?ai=true";
            }
            console.log("isHosting", this.isadmin);
            this.api.post(url, { data: { isHosting: this.isadmin, isJson: true } }).then((res) => {
                console.log(res);
                if (!this.timeout) {
                    this.timeout = setTimeout(() => {
                        this.updateMessages(this.scrollDown);
                    }, this.timeToUpdate);
                }
                if (res.users) {
                    this.users = res.users;
                    this.others = Object.values(res.users)
                        .filter((e) => e.user_nicename != res.me)
                        .map((e) => e.name);
                    if (!this.others.length) {
                        this.others = null;
                        if (!this.isadmin) {
                            this.others = [this.entry.property.primaryHost.first_name];
                        }
                    }
                    this.$store.state.me = res.me;
                }
                if (res.messages) {
                    if (this.msgData.length) {
                        this.msgData = this.msgData.concat(res.messages);
                    } else {
                        this.msgData = res.messages;
                    }
                    if (fn) {
                        fn();
                    }
                }
            });
        },
        updateChat(fn) {
            var baseurl = $(".chat-content").data("updateurl");
            //console.log(baseurl);
            var lastMessageId = $(".msg-entry:last-child").data("msgid");
            if (!lastMessageId) {
                lastMessageId = "";
            }
            var isHosting = $(".chat-content").data("ishosting");
            //console.log(lastMessageId);
            if (!$.requestPending) {
                $.requestPending = true;
                $.ajax({
                    type: "POST",
                    url: baseurl + lastMessageId,
                    data: {
                        is_hosting: isHosting,
                    },
                    success: function (data) {
                        if (data != "") {
                            //$(".msg-entry:last-child").after(data);
                            $(".chat-content").append(data);
                            chatScrolltoBottom();
                        }
                    },
                    complete: function () {
                        $.requestPending = false;
                    },
                });
            }
        },
        sendMessage(msg) {
            const username = this.userData.userName;
            const avatar = this.userData.userImage;

            this.pushMsgData({
                from: {
                    name: "DevplaCalledMe",
                    avatar: avatar,
                },
                msg,
            });

            this.$sendMessage({
                name: username,
                msg,
                avatar: avatar,
            });

            setTimeout(() => {
                const element = document.getElementById("chat__body");
                element.scrollTop = element.scrollHeight;
            }, 0);
        },
    },
};
</script>

<style scoped>
.chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 100px;
    padding-top: 40px;
}
.chatbar {
    position: fixed;
    width: 100%;
}
.chat__header {
    background: #ffffff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    border-radius: 24px 24px 0px 0px;
    padding: 1.8rem;
    font-size: 16px;
    font-weight: 700;
}

.chat__header__greetings {
    color: #292929;
}
</style>
