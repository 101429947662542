<template>
    <div style="max-width: 600px; margin: 0 auto;">
        <v-container fluid>
            <div v-if="guide" id="content" class="mt-16">
                <SingleGuide :guide="guide" :icons="icons" :currentReservation="reservation"></SingleGuide>
            </div>
            <t-footer></t-footer>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import PageCover from "@/components/PageCover";
import ReservationVisual from "@/components/ReservationVisual.vue";

import Footer from "@/components/Footer.vue";
import SingleGuide from "../../../common/components/hospitality/SingleGuide.vue";
export default {
    name: "guide",
    props: ["reservation", "entry"],
    components: {
        "page-cover": PageCover,
        "t-footer": Footer,
        SingleGuide,
        ReservationVisual
    },
    data: () => ({
        onlineCheckinCompleted: false,
        headerImage: null,
        headerTitle: null,
        guide: null,
        icons: {
            parking: "parking",
            transportation: "sign",
            checkin: "hand-key",
        },
    }),
    created() {
        this.headerImage = this.entry.cover_img || null;
        this.headerTitle = this.entry.header_content || null;
        const guideId = this.$route.params.guide_id;
        this.api.get("getguide/" + this.reservation.reservation_id + "/" + guideId + "/a3915eb049f3f438?json=1").then((res) => {
            this.guide = res.guide;
            this.headerTitle = this.guide.title;
        });
    },
    methods: {
        nl2br(str, replaceMode, isXhtml) {
            var breakTag = isXhtml ? "<br />" : "<br>";
            var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
            return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
        setupBg(bgurl) {
            this.coverBg = "background: url('" + bgurl + "') center / cover;";
            this.$store.state.coverBg = this.coverBg;
            //console.log(this.$store.state.coverBg);
        },
    },
};
</script>
<style>
#content {
    max-width: 600px;
    margin: 0 auto;
}
</style>
