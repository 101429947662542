export default {
    adults: {
        default: 1,
    },
    babies: {
        default: 0,
        ageRequired: true,
        defaultAge: 2,
    },
};
