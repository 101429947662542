<template>
    <div>
        <section class="reservation-info text-center mb-5 mt-2 mx-4" v-if="!skiprender">
            <div v-if="!this.$store.state.reservation.property_name" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div v-if="this.$store.state.reservation.property_name">
                <p class="subtitle-2 text-uppercase grey--text font-weight-bold">
                    {{ this.btr("You have booked") }}
                </p>
                <p>
                    {{ this.btr("From") }}
                    {{ this.$store.state.reservation.date_start_hr }}
                    {{ this.btr("to") }} {{ this.$store.state.reservation.date_end_hr }}
                    {{ this.$store.state.reservation.date_end_year }}
                </p>
                <h3 class="font-weight-bold">{{ this.$store.state.reservation.property_name }}</h3>
                <div class="text-subtitle-1" v-if="this.$store.state.reservation.property_address">
                    <div>{{ this.$store.state.reservation.property_address }}</div>
                    <div>{{ this.$store.state.reservation.property_city }}</div>
                    <v-btn
                        class="mt-0"
                        color="primary"
                        text
                        rel="noreferrer"
                        target="_blank"
                        :href="'https://www.google.com/maps?f=q&source=s_q&q=' + this.$store.state.reservation.property_address + ' ' + this.$store.state.reservation.property_city"
                        >{{ btr("Open with google maps") }}</v-btn
                    >
                    <v-list-item-avatar tile size="64" v-if="false">
                        <a
                            style="display: inline-block; width: 64px; height: 64px"
                            rel="noreferrer"
                            target="_blank"
                            :href="'https://www.google.com/maps?f=q&source=s_q&q=' + this.$store.state.reservation.property_address + ' ' + this.$store.state.reservation.property_city"
                        >
                            <img src="../assets/img/icon-pin-map.svg" class="no-border-radius" />
                        </a>
                    </v-list-item-avatar>
                    <div v-if="false">
                        <v-btn
                            color="primary"
                            text
                            rel="noreferrer"
                            target="_blank"
                            :href="'https://www.google.com/maps?f=q&source=s_q&q=' + this.$store.state.reservation.property_address + ' ' + this.$store.state.reservation.property_city"
                            >{{ btr("Open with google maps") }}</v-btn
                        >
                    </div>
                </div>
            </div>
            <v-divider class="my-4"></v-divider>
            <div class="d-flex justify-center grey--text">
                <span>{{ btr("Reservation") }}</span>
                <span><img src="@/assets/img/airbnb-1.svg" alt="" width="20" class="mx-4" /></span>
                <span>{{ this.$store.state.reservation.reservation_id ? this.$store.state.reservation.reservation_id.substring(0, 16) : "" }}</span>
            </div>
        </section>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: ["reservationdata", "passingProp", "skipheader", "skiprender"],
    data() {
        return {
            headerContent: "Tomokit",
            reservation: null,
        };
    },
    methods: {
        setupBg(bgurl) {
            this.coverBg = "background: url('" + bgurl + "') center / cover;";
            this.$store.state.coverBg = this.coverBg;
            this.$store.state.coverImg = bgurl;
            //console.log(this.$store.state.coverBg);
        },
        setupReservation(r, d) {
            this.reservation = r;

            localStorage.reservation_id = r.reservation_id;
            localStorage.reservation_token = r.token;
            this.$store.state.reservation = r;
            console.log(this.$store.state.reservation);

            //this.reservation = true;
            this.$store.state.btrLang = r.guest_language;
            localStorage.btrLang = r.guest_language;
            localStorage.reservation_telephone = r.telephone;
            if (d) {
                this.reservationData = d;
                this.$store.state.reservationData = d;
                if (!this.skipheader) {
                    this.$store.state.headerContent = d.header_content;
                    this.headerContent = d.header_content;
                }
                this.staying = d.staying;
                this.setupBg(d.cover_img);
            }
        },
    },
    watch: {
        reservationdata(r) {
            this.setupReservation(r);
        },
    },
    created: function () {
        if (this.passingProp) {
            console.log("%c Setting up reservation from footer, passed", "background: #222; color: #bada55");
            //this.setupReservation(this.reservation);
        } else if (!this.$store.state.reservationDataRefreshed && !this.$store.state.reservation.property_name && (this.$route.params.reservation_id || localStorage.reservation_id)) {
            console.log("%c Setting up reservation from footer", "background: #222; color: #bada55");
            var reservation_id = this.$route.params.reservation_id ? this.$route.params.reservation_id : localStorage.reservation_id;
            this.$store.state.headerContent = "Your Reservation " + reservation_id;
            axios
                .get(this.$store.state.appUrl.api + "reservations/" + reservation_id + "/a3915eb049f3f438?json=1")
                .then((res) => {
                    this.setupReservation(res.data.reservation, res.data);
                    this.$emit("update");
                })
                .catch((err) => console.log(err));
        }
    },
};
</script>
