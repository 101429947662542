<template>
    <div>
        <v-row>
            <v-col>
                <v-stepper v-model="e6" vertical class="mx-auto" style="max-width: 500px">
                    <v-stepper-step :complete="e6 > 1" step="1" color="#40929d">{{ btr("Will you have an internet connection at check-in?") }}</v-stepper-step>

                    <v-stepper-content step="1">
                        <v-radio-group v-model="cData.hasInternetConnection" :mandatory="true" class="mb-4">
                            <v-radio :label="btr('Yes, my phone will be connected')" value="1" color="#40929d"></v-radio>
                            <v-radio :label="btr('No, I won\'t have a connection')" value="0" color="#40929d"></v-radio>
                            <v-radio :label="btr('I don\'t know yet')" value="-1" color="#40929d"></v-radio>
                        </v-radio-group>
                        <v-btn color="#40929d" class="white--text" @click="e6 = 2">{{ btr("Next") }}</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="e6 > 2" step="2" color="#40929d">{{ btr("Do you already know what time you will arrive?") }}</v-stepper-step>

                    <v-stepper-content step="2">
                        <v-row>
                            <v-col xs="6" md="4">
                                <v-select :items="arrivalPrefixItems" outlined v-model="cData.arrivalTimePrefix"></v-select>
                            </v-col>
                            <v-col xs="6" md="4">
                                <v-text-field type="time" v-model="cData.arrivalTime" required :rules="[(v) => !!v || btr('Please fill this field')]"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="earlyCheckinRequest">
                            <v-col>
                                <v-checkbox v-model="earlyCheckin" color="primary" :label="this.btr('If possible, i would check-in earlier')"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-btn color="#40929d" class="white--text" @click="e6 = 3" :disabled="!cData.arrivalTime">{{ btr("Next") }}</v-btn>
                        <v-btn text @click="e6 = 1">{{ btr("Previous") }}</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="e6 > 3" step="3" color="#40929d">{{ btr("For how many people have you booked?") }}</v-stepper-step>
                    <v-stepper-content step="3">
                        <div v-if="occupancy" class="mb-4">
                            <v-text-field outlined readonly :value="getGuests"></v-text-field>
                            <div v-for="(category, cat) in occupancyCategories" :key="cat">
                                <v-row>
                                    <v-col>
                                        <strong>{{ getText(category.label, cat[0].toUpperCase() + cat.substring(1)) }}</strong>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-btn class="mx-2" fab x-small outlined color="#40929d" @click="setOccupancy(cat, -1)">
                                            <v-icon dark>mdi-minus</v-icon>
                                        </v-btn>

                                        <strong>{{ occupancy[cat] }}</strong>

                                        <v-btn class="mx-2" fab x-small outlined color="#40929d" @click="setOccupancy(cat, +1)">
                                            <v-icon dark>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="category.ageRequired && occupancy[cat]">
                                    <v-col>
                                        <v-row>
                                            <v-col>
                                                <strong>{{ getText(category.ageQuestion, "How old are the children?") }}</strong>
                                            </v-col>
                                        </v-row>
                                        <div v-for="(b, index) in cData.guests[cat]" :key="index">
                                            <v-select outlined :items="babyYears" value="2 years" v-model="b.age"></v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <v-btn color="#40929d" class="white--text" @click="e6 = 4">{{ btr("Next") }}</v-btn>
                        <v-btn text @click="e6 = 2">{{ btr("Previous") }}</v-btn>
                    </v-stepper-content>

                    <v-stepper-step step="4" color="#40929d">{{ btr("If needed, what number can I use to contact you?") }}</v-stepper-step>
                    <v-stepper-content step="4">
                        <v-text-field outlined v-model="cData.telephone" :rules="[(v) => !!v || btr('Please fill this field')]" placeholder="+3933..."></v-text-field>
                        <v-btn color="#40929d" class="white--text" @click="collectOnlineCheckin()" :disabled="!cData.telephone">{{ btr("Submit") }}</v-btn>
                        <v-btn text @click="e6 = 3">{{ btr("Previous") }}</v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import axios from "axios";
import occupancyCategories from "../../../common/components/data/occupancycategories.js";
export default {
    props: ["entry", "reservation"],
    data: () => ({
        //Checkin Data
        cData: {
            occupancy: {},
            //detailed, used for data collection
            guests: {},
            //legacy
            guest_adults: 1,
            guest_babies: [],
            telephone: null,
            arrivalTime: null,
        },
        occupancy: null,
        occupancyCategories: {
            adults: {
                default: 1,
            },
            babies: {
                default: 0,
                ageRequired: true,
            },
        },
        arrivalPretext: "After ",
        arrivalTime: "",
        earlyCheckinRequest: false,
        earlyCheckin: false,
        telephone: "",
        e6: 1,
        e1: 0,
        hasInternetConnection: "1",
        adults: 1,
        babies: 0,
        babies_age: [],
        babyYears: [],
    }),
    watch: {
        arrivalTime(val) {
            if (this.checkinTime) {
                //console.log(this.checkinTime);
                //console.log("now " + val);
                if (val < this.checkinTime) {
                    this.earlyCheckinRequest = true;
                } else {
                    this.earlyCheckin = false;
                    this.earlyCheckinRequest = false;
                }
            }
        },
    },
    mounted: function () {
        this.occupancyCategories = occupancyCategories;
        this.babyYears.push({ text: "1 " + this.btr("year"), value: 1 });
        for (var i = 1; i < 18; i++) {
            this.babyYears.push({ text: i + " " + this.btr("years"), value: i });
        }
        const r = this.reservation;
        if (r.telephone) {
            this.cData.telephone = r.telephone;
        }
        if (localStorage.reservation_telephone && !this.cData.telephone && localStorage.reservation_telephone != "undefined") {
            this.cData.telephone = localStorage.reservation_telephone;
        }
        this.cData.hasInternetConnection = r.hasInternetConnection ? r.hasInternetConnection : this.cData.hasInternetConnection;
        this.cData.arrivalTimePrefix = r.arrivalTimePrefix ? r.arrivalTimePrefix : this.cData.arrivalTimePrefix;
        this.cData.arrivalTime = r.arrivalTime ? r.arrivalTime : this.cData.arrivalTime;
        const occupancy = {};
        console.log("the oc", r);
        for (const k in this.occupancyCategories) {
            occupancy[k] = this.occupancyCategories[k].default || 0;
            this.cData.guests[k] = [];
            if (r.occupancy && r.occupancy[k]) {
                occupancy[k] = r.occupancy[k];
            }
            this.cData.guests[k] = [];
            if (r.guests && r.guests[k]) {
                this.cData.guests[k] = r.guests[k];
            }
        }
        this.occupancy = occupancy;

        //this.babyYears = r.guest_babyYears ? r.guest_babyYears : this.babyYears;
    },
    methods: {
        setOccupancy(cat, n) {
            this.occupancy[cat] += n;
            console.log(cat, this.occupancy[cat]);
            if (this.occupancy[cat] <= 0) {
                this.occupancy[cat] = 0;
            } else {
                if (this.occupancyCategories[cat] && this.occupancyCategories[cat].ageRequired) {
                    if (!this.cData.guests[cat]) {
                        this.cData.guests[cat] = [];
                    }
                    for (let i = 0; i < this.occupancy[cat]; i++) {
                        if (!this.cData.guests[cat][i]) {
                            this.cData.guests[cat].push({ age: this.occupancyCategories[cat].defaultAge || 0 });
                        }
                    }
                    const diff = this.cData.guests[cat].length - this.occupancy[cat];
                    if (diff > 0) {
                        this.cData.guests[cat].splice(this.cData.guests[cat].length - (diff - 1), diff);
                    }
                }
            }
            //this.$set(this.cData, "occupancy", this.occupancy);
        },
        collectOnlineCheckin: function () {
            const body = {
                reservation_id: localStorage.reservation_id,
                token: localStorage.reservation_token,
            };
            this.cData.occupancy = this.occupancy;
            body.data = this.cData;
            body.data.arrivalTimeVal = this.cData.arrivalTimePrefix + this.cData.arrivalTime;
            this.api.put("reservations/checkin", { body: JSON.stringify(body), noJson: true }).then((res) => {
                try {
                    res = JSON.parse(res);
                    this.$emit("reservationUpdate", res.data);
                } catch (e) {
                    console.log("error");
                }
                console.log(res);
                this.$router.push("/");
            });
        },
    },
    computed: {
        checkinTime: function () {
            return this.$store.state.checkinTime ? "14:00" : this.$store.state.checkinTime;
        },
        arrivalPrefixItems: function () {
            return [
                { text: this.btr("After"), value: "After " },
                { text: this.btr("At"), value: "At " },
            ];
        },
        getGuests: function () {
            const babies = this.occupancy.babies;
            var adultString = this.occupancy.adults > 1 ? " " + this.btr("adults") + ", " : " " + this.btr("adult") + ", ";
            var babyString = babies > 1 ? " " + this.btr("babies") : " " + this.btr("baby");
            return this.occupancy.adults + adultString + babies + babyString;
        },
    },
};
</script>
<style>
.v-stepper__label {
    font-size: 1.2rem;
}
</style>
