<template>
    <v-app>
        <v-navigation-drawer v-if="$store.state.chatDrawer" v-model="$store.state.chatDrawer" app clipped right
            id="drawer-chat"
            :width="this.$vuetify.breakpoint.name != 'xs' && this.$vuetify.breakpoint.name != 'sm' ? 480 : '100%'">
            <v-toolbar elevation="0" style="position: fixed; width: 100%">
                <v-btn text @click="$store.state.chatDrawer = false">x {{ btr("close") }}</v-btn>
            </v-toolbar>
            <div class="pt-12">
                <chat subject="reservation_id" :id="reservation_id" :isadmin="false" :entry="entry"
                    scrollable="#drawer-chat .v-navigation-drawer__content"></chat>
            </div>
        </v-navigation-drawer>
        <v-navigation-drawer v-model="drawer" app right>
            <v-list dense>
                <v-list-item v-for="(item, k) in navigation" :key="k" :to="item.to || null"
                    @click="callback(item, 'click')">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ btr(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item></v-list>
        </v-navigation-drawer>
        <v-app-bar app elevation="0" fixed color="#fff">
            <v-btn icon @click.stop="backHome" v-if="$route.name == 'guide'">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
                <img src="@/assets/img/logo-tomokit-full.svg" height="16px" class="mr-2 mt-2" style="" />
            </v-toolbar-title>
            <div class="icon-group" v-if="false">
                <v-btn icon :href="this.$store.state.reservation.chat_url">
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
                <v-btn icon>
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="#b2c0d0"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-main>
            <router-view v-if="reservation" :reservation="reservation" :entry="entry"
                @reservationUpdate="reservationUpdate" />
            <v-container v-else fill-height class="relative">
                <div class="loader text-center">
                    <img src="@/assets/img/logo-tomokit.svg" height="60px" />
                    <div class="px-16 mt-4">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </div>
                    <div v-if="problems" class="mt-8">
                        <v-btn href="https://wa.me/+393935639394" color="primary" elevation="0" x-large><v-icon
                                class="mr-2">mdi-whatsapp</v-icon> Problemi? Clicca qui</v-btn>
                    </div>
                </div>
            </v-container>
            <v-dialog v-model="$store.state.languageDialog" fullscreen transition="dialog-top-transition" class="pa-0">
                <v-card><v-card-text class="mt-12 pa-0 pt-12"> <language-selector v-if="reservation"
                            :reservation="reservation" :entry="entry" /> </v-card-text></v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<script>
import Chat from "../../chat/views/Chat";
import LanguageSelector from "./views/LanguageSelector.vue";
export default {
    components: {
        "language-selector": LanguageSelector,
        Chat,
    },
    data() {
        return {
            drawer: false,
            reservation_id: null,
            entry: null,
            reservation: null,
            problems: false,
            updateTimer: false,
            lastUpdate: 0,
            minutesUpdate: 5,
            navigation: [
                { icon: "mdi-home", to: "/", title: "My Reservation" },
                {
                    icon: "mdi-web",
                    click: function () {
                        this.drawer = false;
                        this.$store.state.languageDialog = true;
                    },
                    title: "Change Language",
                },
                {
                    icon: "mdi-message",
                    click: function () {
                        this.drawer = false;
                        this.$store.state.chatDrawer = true;
                    },
                    title: "Chat with the host",
                },
                // {icon: "mdi-cog", to: "/online-checkin", title: "Reservation Settings"},
                // {icon: "mdi-cog", to: "/chat", title: "Chat with the host"},
            ],
        };
    },
    watch: {
        $route(r) {
            this.getReservation();
        },
        reservation() {
            if (this.reservation) {
                if (!localStorage.languageSelected) {
                    this.$store.state.languageDialog = true;
                }
            }
        },
    },
    created() {
        this.getReservation();
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                if (this.updateTimer) {
                    console.log("deactivating updates...");
                    clearTimeout(this.updateTimer);
                    this.updateTimer = false;
                }
            } else {
                if (!this.updateTimer) {
                    const diff = Date.now() - this.lastUpdate;
                    console.log("seconds", diff / 1000);
                    let toNextUpdate = 1000 * 60 * this.minutesUpdate;
                    toNextUpdate = toNextUpdate - diff;
                    if (toNextUpdate < 0) {
                        console.log("updating now...");
                        this.getReservation(true);
                    } else {
                        console.log(`setting up timer... in ${toNextUpdate / 1000} seconds`);
                        this.updateTimer = setTimeout(() => {
                            console.log("refreshing data...");
                            this.getReservation(true);
                        }, toNextUpdate);
                    }
                }
            }
        });
    },
    methods: {
        reservationUpdate(r) {
            for (const k in r) {
                this.reservation[k] = r[k];
            }
        },
        backHome() {
            this.$router.push("/");
        },
        callback(item, cbname) {
            if (item[cbname]) {
                item[cbname].apply(this);
            }
        },
        getReservation(fforce = false) {
            this.$store.state.btrLang = localStorage.btrLang || "en";
            let timeout = setTimeout(() => {
                this.problems = true;
            }, 5000);
            if (this.$route.params.booking_pid) {
                this.api.get("reservations/booking/?bookingId=" + this.$route.params.booking_pid).then((res) => {
                    this.reservation = res.reservation;
                    this.entry = res;
                });
                return false;
            }
            const forceReload = this.$route.fullPath.indexOf("/?found=1") !== -1 || this.$route.fullPath.indexOf("/?reload=1") !== -1;
            if (!this.reservation || forceReload || fforce) {
                this.lastUpdate = Date.now();
                this.reservation_id = this.reservation_id || this.$route.params.reservation_id || localStorage.reservation_id;
                console.log("%c no data found: loading... " + this.reservation_id, "background: #222; color: #bada55");
                this.api.get("reservations/" + this.reservation_id + "/a3915eb049f3f438?json=1").then((res) => {
                    this.entry = res;
                    clearTimeout(timeout);
                    if (!res.reservation.telephone || (!res.reservation.guest_name && res.reservation.guest_name.indexOf("Guest") === 0)) {
                        if (this.$route.path != "/confirm-details") {
                            this.$router.push("/confirm-details");
                        }
                    }
                    this.reservation = res.reservation;
                    localStorage.reservation_id = res.reservation.reservation_id;
                    localStorage.reservation_token = this.reservation.token;
                });
            }
            this.updateTimer = setTimeout(() => {
                console.log("refreshing data...");
                this.getReservation(true);
            }, 1000 * 60 * this.minutesUpdate);
        },
    },
};
</script>
<style>
.relative {
    position: relative;
}

.loader {
    position: absolute;
    top: 40%;
    width: 100%;
}
</style>
