<template>
    <div v-if="guests">
        <v-stepper v-model="step" vertical class="mx-auto ml-n4" style="max-width: 500px">
            <div v-for="(guest, gk) in guests" :key="gk">
                <v-stepper-step :step="gk" color="#40929d" @click="step = gk">
                    {{ gk == 0 ? btr("Main guest") : btr("Guest") + " #" + (gk + 1) }}
                    {{ guest.name ? ": " + guest.name : "" }}
                </v-stepper-step>
                <v-stepper-content :step="gk">
                    <div v-for="(field, fk) in gk ? fields.sec : fields.main" v-bind:key="`f-${fk}`">
                        <div v-if="!field.vIf || guest[field.vIf.key] == field.vIf.value">
                            <div v-if="field.fullsizeLabel" class="mb-2 font-weight-bold">
                                {{ field.fullsizeLabel ? field.fullsizeLabel : field.name.charAt(0).toUpperCase() + field.name.slice(1) }}
                            </div>
                            <v-text-field
                                v-if="!field.type || field.type == 'text'"
                                outlined
                                v-model="guest[field.name]"
                                :rules="[(v) => !!v || btr('Please fill this field')]"
                                :placeholder="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                                :label="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                            ></v-text-field>
                            <v-select
                                v-else-if="field.type == 'select' && field.itemText"
                                :label="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                                :items="field.items"
                                outlined
                                v-model="guest[field.name]"
                                :item-text="field.itemText"
                                :item-value="field.itemValue"
                            ></v-select>
                            <v-select
                                v-else-if="field.type == 'select'"
                                :label="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                                :items="field.items"
                                outlined
                                v-model="guest[field.name]"
                            ></v-select>
                            <v-autocomplete
                                autocomplete="nope"
                                v-else-if="field.type == 'autocomplete'"
                                outlined
                                :menu-props="{ rounded: 'xl' }"
                                v-model="guest[field.name]"
                                :items="field.items"
                                hide-no-data
                                hide-selected
                                :item-text="field.itemText"
                                :item-value="field.itemValue"
                                :label="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                                prepend-inner-icon="mdi-database-search"
                            ></v-autocomplete>
                            <file-upload
                                v-else-if="field.type == 'file'"
                                :label="field.label ? field.label : field.name.charAt(0).toUpperCase() + field.name.slice(1)"
                                v-model="guest[field.name]"
                                :uploadurl="field.uploadurl"
                            ></file-upload>
                            <v-menu
                                v-else-if="field.type == 'date'"
                                :ref="menu_refs[gk + '_' + fk]"
                                v-model="menu_refs[gk + '_' + fk]"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="guest.birthdate" label="Birth date" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" outlined readonly></v-text-field>
                                </template>
                                <v-date-picker v-model="guest.birthdate" no-title @input="menu_refs[gk + '_' + fk] = false"></v-date-picker>
                            </v-menu>
                        </div>
                    </div>
                    <div class="mt-4">
                        <v-btn v-if="gk > 0" color="#40929d" class="white--text mr-4" @click="step -= 1">{{ btr("Previous") }}</v-btn>
                        <v-btn v-if="gk + 1 < guests.length" color="#40929d" class="white--text" @click="step += 1">{{ btr("Next") }}</v-btn>
                        <v-btn v-if="gk + 1 == guests.length" color="#40929d" class="white--text" @click="saveData()">{{ btr("Save") }}</v-btn>
                    </div>
                </v-stepper-content>
            </div>
        </v-stepper>
    </div>
</template>
<script>
import cities from "@/staticdata/italy/cities.json";
import states from "@/staticdata/italy/states.json";
import guesttype from "@/staticdata/italy/guesttype.json";
import documents from "@/staticdata/italy/documents.json";
import FileUpload from "../../../common/components/FileUpload";
export default {
    props: ["entry"],
    components: {
        FileUpload,
    },
    data: () => {
        return {
            //helper
            menu_refs: {},
            fields: {},
            guests: null,
            reservation: null,
            guestCategories: ["adults", "babies"],
            step: 0,
        };
    },
    methods: {
        saveData() {
            const body = {
                reservation_id: localStorage.reservation_id,
                token: localStorage.reservation_token,
            };
            const cData = { guestDataSent: 1 };
            cData.guests = this.guests;
            body.data = cData;
            this.api.put("reservations/checkin", { body: JSON.stringify(body), noJson: true }).then((res) => {
                try {
                    res = JSON.parse(res);
                    this.$emit("reservationUpdate", res.data);
                } catch (e) {
                    console.log("error");
                }
                console.log(res);
                this.$router.push("/");
            });
        },
    },
    mounted() {
        this.reservation = this.entry.reservation;

        const secFieldset = [
            {
                name: "name",
                label: "First name",
            },
            { name: "lastname", label: "Last name" },
            { name: "gender", type: "select", items: ["male", "female"] },
            { name: "birthdate", label: "Birth date", type: "date" },
        ];
        let st, docs, gtypes;
        let citarr = [];
        if (states) {
            st = states.map((e) => {
                return { code: e.Codice, name: e.Descrizione };
            });
        }
        if (documents) {
            docs = documents.map((e) => {
                return {
                    code: e.Codice,
                    name: e.Descrizione,
                };
            });
        }
        if (cities) {
            for (const city of cities) {
                citarr.push({ code: city.Codice, name: `${city.Descrizione}, ${city.Provincia}` });
            }
        }
        if (st) {
            secFieldset.push({
                name: "birthstateCode",
                type: "autocomplete",
                label: "Birth country",
                fullsizeLabel: "In which country were you born?",
                items: st,
                itemText: "name",
                itemValue: "code",
            });
            if (cities) {
                secFieldset.push({
                    vIf: { key: "birthstate", value: 100000100 },
                    name: "birthcityCode",
                    type: "autocomplete",
                    label: "Birth city",
                    fullsizeLabel: "In which city were you born?",
                    items: citarr,
                    itemText: "name",
                    itemValue: "code",
                });
            }
            secFieldset.push({
                name: "nationalityCode",
                type: "autocomplete",
                label: "Nationality",
                items: st,
                itemText: "name",
                itemValue: "code",
            });
        }
        if (st) {
            secFieldset.push({
                name: "documentstateCode",
                type: "autocomplete",
                fullsizeLabel: "in which country was the document issued?",
                items: st,
                itemText: "name",
                itemValue: "code",
                label: "Issuing country",
            });
        }
        if (cities) {
            secFieldset.push({
                vIf: { key: "documentstate", value: 100000100 },
                name: "documentcityCode",
                type: "autocomplete",
                fullsizeLabel: "in which city was the document issued?",
                items: citarr,
                itemText: "name",
                itemValue: "code",
                label: "Issuing city",
            });
        }
        if (docs) {
            secFieldset.push({
                name: "documentTypeCode",
                type: "select",
                label: "Document type",
                items: docs,
                itemText: "name",
                itemValue: "code",
            });
        }
        secFieldset.push({ name: "documentnumber", label: "Document number" });
        secFieldset.push({
            name: "documentImage",
            label: "picture of your document",
            type: "file",
            uploadurl: "reservation_uploads/" + this.reservation.reservation_id,
        });
        this.fields.main = secFieldset;
        this.fields.sec = secFieldset;
        const gg = this.reservation.guests || {};
        const guests = this.reservation.guests && this.reservation.guests[0] ? this.reservation.guests : [];
        const occupancy = this.reservation.occupancy || { adults: 1 };
        let counter = 0;
        for (const k in occupancy) {
            for (let i = 0; i < occupancy[k]; i++) {
                if (!guests[counter]) {
                    if (gg[k] && gg[k][i]) {
                        guests.push(gg[k][i]);
                    } else {
                        guests.push({});
                    }
                }
                guests[counter].guestCategory = k;
                if (!counter) {
                    guests[counter].guestTypeCode = 18;
                } else {
                    guests[counter].guestTypeCode = 20;
                }
                counter += 1;
            }
        }
        if (guests.length == 1) {
            guests[0].guestTypeCode = 16;
        }
        this.guests = guests;
        console.log("the guests", guests);
    },
};
</script>
