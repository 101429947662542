import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=4d0c6b8e&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../tomokit-guest-app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports