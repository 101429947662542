export default {
    data: () => ({
        api: null,
    }),
    methods: {
        getBaseUrl(envApi) {
            for (const k in this.api.apiSettings) {
                if (top.location.href.includes(k)) {
                    return envApi ? this.api.apiSettings[k].api : this.api.apiSettings[k].assets;
                }
            }
        },
    },
    created() {
        this.api = {
            apiSettings: {
                "http://localhost": {
                    api: "https://app.tomokit.com/",
                    assets: "https://app.tomokit.com/host/",
                },
                "http://192.168.1": {
                    api: "http://{{baseurl}}:8888/tomokit-com/server/",
                    assets: "http://{{baseurl}}:8080/",
                },
                "//my.tomokit.com": {
                    api: "https://app.tomokit.com/",
                    assets: "https://my.tomokit.com/",
                },
                "//app.tomokit.com/": {
                    api: "https://app.tomokit.com/",
                    assets: "https://app.tomokit.com/host/",
                },
                "//beta.tomokit.com:8080": {
                    api: "https://beta.tomokit.com/",
                    assets: "http://beta.tomokit.com:8080/",
                },

                "//beta.tomokit.com:7000": {
                    api: "https://beta.tomokit.com/",
                    assets: "http://beta.tomokit.com:7000/",
                },
                "//beta.tomokit.com": {
                    api: "https://beta.tomokit.com/",
                    assets: "https://beta.tomokit.com/host/",
                },
            },
            getBaseUrl(envApi) {
                for (const k in this.apiSettings) {
                    if (top.location.href.includes(k)) {
                        return envApi ? this.apiSettings[k].api : this.apiSettings[k].assets;
                    }
                }
            },
            async get(url, options = {}) {
                const myHeaders = new Headers();
                if (options.data) {
                    url + `?${options.data.join("")}`;
                }
                if (localStorage.token) {
                    myHeaders.append("Authorization", `Bearer ${localStorage.token}`);
                }
                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                    credentials: "include",
                };
                const res = await fetch(this.getBaseUrl("api") + url, requestOptions);
                return await res.json();
            },
            async put(url, options = {}) {
                options.method = "PUT";
                return await this.send(url, options);
            },
            async post(url, options) {
                options.method = "POST";
                return await this.send(url, options);
            },
            async upload(url, files, options = {}) {
                options.method = "POST";
                options.files = files;
                return await this.send(url, options);
            },
            async send(url, options) {
                const myHeaders = new Headers();
                let data = null;
                if (options.data) {
                    data = new FormData();
                    for (const p in options.data) {
                        data.append(p, options.data[p]);
                    }
                }
                if (options.files) {
                    data = data || new FormData();
                    for (const file of options.files) {
                        data.append(file.fieldname, file.file, file.file.name);
                    }
                }
                if (options.body) {
                    data = options.body;
                }
                if (localStorage.token) {
                    myHeaders.append("Authorization", `Bearer ${localStorage.token}`);
                }
                const method = options.method ? options.method : "POST";
                const requestOptions = {
                    method: method,
                    headers: myHeaders,
                    body: data,
                    redirect: "follow",
                    credentials: "include",
                };
                console.log(this.getBaseUrl("api"));
                const res = await fetch(this.getBaseUrl("api") + url, requestOptions);
                return (await options.noJson) ? res.text() : res.json();
            },
        };
        const baseurl = top.location.href.replace("http://", "").split(":")[0];
        this.api.apiSettings["http://192.168.1"].api = this.api.apiSettings["http://192.168.1"].api.replace("{{baseurl}}", baseurl);
        this.api.apiSettings["http://192.168.1"].assets = this.api.apiSettings["http://192.168.1"].assets.replace("{{baseurl}}", baseurl);
    },
};
