import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Master from "./views/Master.vue";
import OnlineCheckin from "@/views/OnlineCheckin.vue";
import DocumentCollection from "@/views/DocumentCollection.vue";
import Guide from "@/views/Guide.vue";
import Checkin from "@/views/Checkin.vue";
import CheckinTrial from "@/views/CheckinTrial.vue";
//import BookingReservation from "@/views/BookingReservation.vue";
import LanguageSelector from "@/views/LanguageSelector.vue";
import ConfirmDetails from "@/views/ConfirmDetails.vue";
Vue.use(Router);

export default new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        },

        {
            path: "/select-language",
            name: "select-language",
            component: LanguageSelector,
        },
        {
            component: ConfirmDetails,
            path: "/confirm-details",
            mane: "confirm-details",
        },
        {
            path: "/master",
            name: "master",
            component: Master,
        },
        {
            path: "/online-checkin",
            name: "online-checkin",
            component: OnlineCheckin,
        },
        {
            path: "/document-collection",
            name: "document-collection",
            component: DocumentCollection,
        },
        {
            path: "/guide/:reservation_id/:guide_id",
            name: "guide",
            component: Guide,
        },
        {
            path: "/booking/:booking_pid",
            name: "from-booking",
            component: ConfirmDetails,
        },
        {
            path: "/checkin",
            name: "checkin",
            component: Checkin,
        },
        {
            path: "/try-checkin",
            name: "trycheckin",
            component: CheckinTrial,
        },
        {
            path: "/:reservation_id",
            name: "reservation",
            component: Home,
        },
        {
            path: "/:reservation_id/document-collection",
            name: "document-collection",
            component: DocumentCollection,
        },
        {
            path: "/:reservation_id/:reservation_hash",
            name: "reservation",
            component: Home,
        },
    ],
});
