<template>
    <div>
        <div id="container mb-8">
            <UniversalCheckin :currentCheckinStep="1" :steps="steps" :isSimulation="false" :showCodes="true" v-if="this.reservation && !this.reservation.checkinDisabled" :reservation="reservation" @stepChange="changeStep" :propertyHeaderImage="propertyHeaderImage" :property="entry.property"></UniversalCheckin>
        </div>
    </div>
</template>
<script>
import PageCover from "@/components/PageCover";
import dragVerify from "@/components/dragVerify";
import UniversalCheckin from "../../../common/components/UniversalCheckin";
export default {
    components: {
        "page-cover": PageCover,
        "drag-verify": dragVerify,
        UniversalCheckin,
    },
    props: ["reservation", "entry"],
    data() {
        return {
            steps: [{}],
            headerTitle: "",
            currentCheckinStep: 1,
            isSimulation: false,
            showCodes: true,
            loading: false,
            overlay: false,
            currentStep: 1,
            stepUtility: [{}],
            propertyHeaderImage: null,
        };
    },
    methods: {
        changeStep(n) {
            //old
            return;
            this.currentStep = +n;
            this.headerTitle = this.steps[this.currentStep].name;
            const step = this.steps[this.currentStep];
            console.log(this.currentStep, step);
            if (step.type == "checkinEnd" || n == this.steps.length - 1) {
                if (this.isSimulation) {
                    return true;
                }
                console.log("putting everything in perspective");
                this.api.put("checkin/" + this.reservation.reservation_id + "/checkincomplete").then((res) => {
                    console.log(res);
                });
            }
        },
        lockVerifiedHandler(nextphase, loading) {
            this.loading = loading;
            this.$set(this.stepUtility[this.currentStep], "smartlockphase2", nextphase);
        },
        lockVerified(direction, step) {
            if (direction == "next") {
                //var vjs = this;
                var myHeaders = new Headers();

                var formdata = new FormData();
                formdata.append("access_pin", this.reservation.access_pin);
                formdata.append("unlock", "1");
                formdata.append("lock_id", step.lock_id || 0);

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                };

                fetch(this.$store.state.appUrl.api + "checkin/" + this.reservation.reservation_id, requestOptions)
                    .then((response) => response.json())
                    .then((result) => console.log(result))
                    .catch((error) => console.log("error", error));
                this.loading = true;
                setTimeout(() => {
                    this.lockVerifiedHandler(true, false);
                }, 1000);
            } else {
                this.lockVerifiedHandler(false, false);
            }
        },
        mount() {
            const c = this.entry.selfcheckins;
            const l = this.$store.state.btrLang;
            let s = Object.values(c)[0];
            if (c[l]) {
                s = c[l];
            } else {
                if (c.en) {
                    s = c.en;
                }
            }
            console.log("s", s);
            for (const st in s.steps) {
                this.steps.push(s.steps[st]);
            }
            this.currentStep = 0;
            this.$nextTick(function () {
                this.currentStep += 1;
            });
            this.headerTitle = this.steps[this.currentStep].name;
            for (const st in s.steps) {
                this.stepUtility.push({ index: st });
            }
            this.propertyHeaderImage = this.entry.cover_img || null;
        },
    },
    created() {
        if (this.entry.selfcheckins) {
            console.log(this.entry.selfcheckins);
            this.mount();
        }
    },
};
</script>
<style>
#container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.step__description img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
}

div.step__description p {
    margin-bottom: 0px;
}

div.step__description li {
    text-align: left;
}
</style>
