<template>
    <div v-if="initialized">
        <div class="mt-8">
            <div v-for="guide in guides" :key="guide.id">
                <v-divider></v-divider>
                <div class="d-flex py-4 pointer" @click="gotoGuide(guide)" style="cursor: pointer;">
                    <img height="28" :src="getIcon(guide.category)" class="mr-4" />
                    <span class="text-primary text-h6">{{ guide.title }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Guide from '../../../common/lib/Guide';
export default {
    props: ["guides", "reservation_id"],
    data: () => ({
        initialized: false,
        a: null,
        icons: {
            transportation: "icon-sign",
            parking: "icon-parking",
            checkin: "icon-parking",
        },
    }),
    methods: {
        getIcon(item, dark = false) {
            if (!Guide.initialized) {
                Guide.init({ baseUrl: this.api.getBaseUrl('api') });
            }
            return Guide.getIcon(item, dark);
        },
        gotoGuide(g) {
            this.$store.state.currentGuide = g;
            this.$router.push("/guide/" + this.reservation_id + "/" + g.id);
        },
    },
    mounted() {
        if (!Guide.initialized) {
            Guide.init({ baseUrl: this.api.getBaseUrl('api') });
        }
        if (!Guide.guideCategories.length) {
            this.api.get('static/assets/app-assets/guides/guide.json').then(res => {
                if (res.categories && res.categories.length) {
                    Guide.guideCategories = res.categories;
                }
                this.initialized = true;
            })
        } else {
            this.initialized = true;
        }
    }
};
</script>
<style></style>
