<template>
    <div v-if="initialized">
        <v-avatar size="80" color="primary" class="mx-auto floating-avatar">
            <img v-if="guide.category" :src="getIcon(guide.category, 'dark')" alt class="g-icon large" />
        </v-avatar>
        <h2 class="text-center my-8">{{ guide.title }}</h2>
        <div v-if="guide.sections.length > 0" class="text-center my-4 mx-4 subtitle1 font-weight-bold" v-html="nl2br(guide.description)"></div>
        <div v-if="guide.sections.length < 1" class="text-center my-4 mx-2" v-html="nl2br(guide.description)"></div>
        <v-expansion-panels v-if="guide.sections" :accordion="true" class="elevation-0" v-model="openSectionPanel">
            <v-expansion-panel v-for="(section, i) in guide.sections" :key="i" class="elevation-0">
                <v-expansion-panel-header style="min-height: 74px">
                    <img v-if="section.icon" :src="getIcon(section.icon)" style="width: 50px; max-height: 40px" class="v-expansion-panel-header__icon ml-0" />
                    <img v-else :src="getIcon(section.icon)" style="width: 50px; max-height: 40px" class="v-expansion-panel-header__icon ml-0" />
                    <v-spacer></v-spacer>
                    <div class="section-title">{{ section.title }}</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content :id="'sect-' + i">
                    <v-divider class="mb-4"></v-divider>
                    <div v-html="nl2br(section.content)"></div>
                    <div v-if="section.google_maps_link && (section.google_maps_link_address || section.google_maps_link_url)">
                        <v-row class="pa-4 my-4 section-widget">
                            <v-col cols="3">
                                <a :href="section.google_maps_link_url ? section.google_maps_link_url : 'https://www.google.com/maps?f=q&source=s_q&q=' + section.google_maps_link_address" rel="noreferrer" target="_blank">
                                    <img src="@/assets/img/icon-pin-map.svg" style="width: 50px" />
                                </a>
                            </v-col>
                            <v-col>
                                <div v-if="section.google_maps_link_address">
                                    {{ section.google_maps_link_address }}
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="section.guides_buttons" class="mt-2">
                        <v-btn v-for="(guideLink, index) in section.guides_links" :key="index" color="primary" class="mb-2" depressed block :to="'/' + 'guide/' + reservation.reservation_id + '/' + guideLink.id">{{ guideLink.title }}</v-btn>
                    </div>
                    <v-btn v-if="section.external_link_url" color="primary" class="my-4" depressed block rel="noreferrer" target="_blank" :href="section.external_link_url">{{ section.external_link_title }}</v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
import Guide from "../../lib/Guide";
export default {
    props: ["guide", "currentReservation", "icons", "currentSectionPanel"],
    data() {
        return {
            reservation: { id: "test" },
            openSectionPanel: null,
            initialized: false,
        };
    },
    methods: {
        getIcon(item, dark = false) {
            if (!Guide.initialized) {
                Guide.init({ baseUrl: this.api.getBaseUrl('api') });
            }
            return Guide.getIcon(item, dark);
        },
        nl2br(str, replaceMode, isXhtml) {
            var breakTag = isXhtml ? "<br />" : "<br>";
            var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
            return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
    },
    watch: {
        currentSectionPanel(v) {
            this.openSectionPanel = v;
            console.log("#sect-" + v);
            if (v) {
                this.$vuetify.goTo("#sect-" + v, { offset: -200 });
            }
        },
    },
    mounted() {
        if (this.currentReservation) {
            this.reservation = this.currentReservation;
        }
        if (!Guide.guideCategories.length) {
            this.api.get('static/assets/app-assets/guides/guide.json').then(res => {
                if (res.categories && res.categories.length) {
                    Guide.guideCategories = res.categories;
                }
                this.initialized = true;
            })
        } else {
            this.initialized = true;
        }
    },
};
</script>
<style>
.v-expansion-panel::before {
    box-shadow: none !important;
}

.section-title {
    font-weight: bold;
    color: #40929d;
    text-transform: uppercase;
}

.section-widget {
    background: #f6f8f9;
}
</style>
