import localizedStrings from "@/locale/it_IT.json";
export default {
    data: () => ({}),
    methods: {
        btr(message, data = false) {
            var msg = "";
            if (!message) {
                return null;
            }
            if (localizedStrings[message]) {
                msg = localizedStrings[message];
            }
            if (this.$store.state.btrLang == "en") {
                msg = message;
            }
            if (!msg.length) {
                msg = message;
            }
            if (data !== false) {
                if (typeof data != "object") {
                    data = [data];
                }
                for (var i = 0; i < data.length; i++) {
                    if (data[i] != null) {
                        msg = msg.replace("%s", data[i]);
                    }
                }
            }
            return msg;
        },
        getText(obj, fallback = null) {
            const lang = this.$store.state.btrLang || "en";
            if (typeof obj == "string" || !obj) {
                if (fallback) {
                    return this.btr(fallback);
                }
                return this.btr(obj);
            }
            if (obj[lang]) {
                return obj[lang];
            }
            return obj["en"] || Object.values(obj)[0];
        },
    },
};
