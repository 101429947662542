<template>
    <v-container class="px-6 mt-16">
        <h2 class="font-weight-700">{{ btr("Welcome %s!", getGuestName()) }}</h2>
        <div class="mt-4">{{ btr("Please select your language") }}</div>
        <div class="language-selector my-6">
            <div @click="selectLanguage('en')" :class="`elevated-1 mb-4 py-6 px-4` + ($store.state.btrLang == 'en' ? ' selected' : '')">
                <img src="@/assets/img/lang-en.svg" class="mr-2 mb-n2" /> English
            </div>
            <div @click="selectLanguage('it')" :class="`elevated-1 mb-4 py-6 px-4` + ($store.state.btrLang == 'it' ? ' selected' : '')">
                <img src="@/assets/img/lang-it.svg" class="mr-2 mb-n2" /> Italiano
            </div>
        </div>
        <t-footer :reservation="reservation" class="mt-12"></t-footer>
    </v-container>
</template>
<script>
import Footer from "@/components/Footer.vue";
export default {
    components: {
        "t-footer": Footer,
    },
    props: ["reservation", "entry"],
    methods: {
        selectLanguage(lang) {
            localStorage.btrLang = this.$store.state.btrLang = lang;
            localStorage.languageSelected = 1;
            this.$store.state.languageDialog = false;
        },
        getGuestName() {
            if (!this.reservation.guest_name || this.reservation.guest_name.indexOf("Guest") !== -1) {
                return "";
            } else {
                let name = this.reservation.guest_name.split(" ");
                if (name[0].length < 3 && name.length > 1) {
                    return name[0] + " " + name[1];
                }
                return name[0];
            }
        },
    },
};
</script>
