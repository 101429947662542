<template>
    <div>
        <v-img v-if="false" :aspect-ratio="16 / 9" :src="headerImage" min-height="250" gradient="to bottom left, rgba(0,0,0,.1), rgba(0,0,0,.7)">
            <v-row align="center" justify="center" class="lightbox white--text pa-4 fill-height">
                <v-col>
                    <h2 class="text-center text-h5">
                        {{ headerTitle }}
                    </h2>
                </v-col>
            </v-row>
        </v-img>
        <v-slide-x-transition>
            <div v-show="step == 2">
                <v-container class="px-6">
                    <v-row class="mt-4">
                        <v-btn icon large><v-icon>mdi-chevron-left</v-icon></v-btn>
                        <v-spacer></v-spacer>
                        <div v-if="vendor" :class="`mt-n0 mr-4 vendor-box box-alone h-3 w-3 ${vendor}-box rounded-lg`">
                            <img :src="require(`@/assets/img/${vendor}-vector.svg`)" alt="" />
                        </div>
                    </v-row>
                    <div class="mt-10">
                        <h2 class="font-weight-700">{{ btr("Confirm your details") }}</h2>
                        <div class="mt-2">{{ btr("First of all, I need to verify that the reservation details match.") }}</div>
                    </div>
                    <div class="checkin-data mt-4 pa-4" v-if="reservation && reservation.checkin">
                        <div class="d-flex">
                            <div class="reservation-date checkin px-4">
                                <div class="font-weight-600">Check in</div>
                                <div class="checkin-date">
                                    {{ reservation.date_start_hr }}
                                </div>
                            </div>
                            <div class="reservation-date px-4">
                                <div class="font-weight-600">Check out</div>
                                <div class="checkin-date">
                                    {{ reservation.date_end_hr }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="checkin-data mt-4 pa-4" v-else>
                        <div class="d-flex">
                            <div class="reservation-date checkin px-4">
                                <div class="font-weight-600">Check in</div>
                                <div class="checkin-date">
                                    <v-text-field :error="errors.date_start" v-model="rData.date_start" type="date" dense hide-details class="ml-n4"></v-text-field>
                                </div>
                            </div>
                            <div class="reservation-date px-4">
                                <div class="font-weight-600">Check out</div>
                                <div class="checkin-date">
                                    <v-text-field :error="errors.date_end" v-model="rData.date_end" type="date" dense hide-details></v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-8">
                        <h2 class="font-weight-700">{{ btr("Your name") }}</h2>
                        <div class="">{{ btr("Enter the same name you used in your %s reservation", getVendor()) }}</div>
                        <v-text-field :error="errors.guest_name" outlined :label="btr('Name')" v-model="rData.guest_name" class="mt-4" :placeholder="btr('Name used on %s', getVendor())"></v-text-field>
                    </div>
                    <div class="mt-4">
                        <h2 class="font-weight-700">{{ btr("Your email") }}</h2>
                        <div class="">{{ btr("Enter your email, will be used for better communication") }}</div>
                        <v-text-field :error="errors.guest_email" outlined :label="btr('Name')" v-model="rData.guest_email" class="mt-4" :placeholder="btr('Email')"></v-text-field>
                    </div>
                    <div class="mt-4">
                        <h2 class="font-weight-700">{{ btr("Your number") }}</h2>
                        <div class="">{{ btr("Enter your telephone number") }}</div>
                        <div class="my-4" v-if="!!errors.wrong_telephone">
                            <v-alert border="right" color="#11A6AC" dark>
                                {{ btr("The phone number you entered is not the same as in your reservation. hint: it ends with %s", errors.wrong_telephone) }}
                            </v-alert>
                        </div>
                        <div class="d-flex mt-4">
                            <v-text-field :return-object="false" outlined class="w-4" :label="btr('Country code')" v-model="rData.countryCode" placeholder="es. +39"></v-text-field>
                            <v-text-field :error="!!errors.wrong_telephone || !!errors.telephone" class="ml-n1 afterselect" outlined :label="btr('Telephone')" v-model="rData.telephone" :placeholder="btr('Your number', getVendor())">
                            </v-text-field>
                        </div>
                    </div>
                    <v-btn @click="collectOnlineCheckin" color="#11A6AC" dark depressed block class="custom-transform-class text-none font-weight-700" x-large>{{ btr("Confirm your details") }}</v-btn>
                    <p class="mt-8 lighcolor">
                        {{ btr("Personal data will be processed in accordance with the provisions of the General Data Protection Regulation, Regulation (EU) 2016/679 of the European Parliament.") }}
                    </p>
                    <t-footer :reservation="reservation" class="mt-12"></t-footer>
                </v-container>
            </div>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <div v-show="step == 1">
                <v-container class="px-6">
                    <div class="hello-box">
                        <v-avatar v-if="property.primaryHost.thumbnail_url" size="64"><img :src="property.primaryHost.thumbnail_url" alt="" /></v-avatar>
                        <div class="mt-4">
                            <h2 class="font-weight-700">{{ btr("Hi, I'm %s", property.primaryHost.first_name.trim()) }}</h2>
                            <h2 class="font-weight-700">{{ btr("Your %s host", getVendor()) }}</h2>
                        </div>
                    </div>
                    <reservation-visual :reservation="reservation" :entry="entry"></reservation-visual>
                    <div class="checkin-data mt-2 pa-4" v-if="reservation && reservation.checkin">
                        <div class="d-flex">
                            <div class="reservation-date checkin px-4">
                                <div class="font-weight-600">Check in</div>
                                <div class="checkin-date">
                                    {{ reservation.date_start_hr }}
                                </div>
                            </div>
                            <div class="reservation-date px-4">
                                <div class="font-weight-600">Check out</div>
                                <div class="checkin-date">
                                    {{ reservation.date_end_hr }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="data-consolidation-prompt mt-4">
                        <div>{{ btr("Please complete the reservation with your details.") }}</div>
                        <v-btn @click="step = 2" color="#11A6AC" dark depressed block class="mt-4 custom-transform-class text-none font-weight-700" x-large>{{ btr("Confirm your details") }}</v-btn>
                    </div>
                    <t-footer :reservation="reservation" class="mt-12"></t-footer>
                </v-container>
            </div>
        </v-slide-x-transition>
    </div>
</template>
<script>
import ReservationVisual from "@/components/ReservationVisual.vue";

import Footer from "@/components/Footer.vue";
export default {
    components: {
        "reservation-visual": ReservationVisual,
        "t-footer": Footer,
    },
    props: ["reservation", "entry"],
    data() {
        return { step: 1, headerImage: null, headerTitle: null, property: null, secondaryImages: [], rData: { countryCode: "" }, countryCodes: [], errors: { guest_name: false } };
    },
    computed: {
        source() {
            return this.reservation.source || null;
        },
        vendor() {
            return this.getVendor(true);
        },
    },
    created() {
        this.getCountryCodes();
        this.headerImage = this.entry.cover_img || null;
        this.headerTitle = this.entry.header_content || null;
        this.property = this.entry.property;
        if (this.property && this.property.listing && this.property.listing.photos) {
            this.secondaryImages.push(this.property.listing.photos[1]);
            if (!this.vendor) {
                this.secondaryImages.push(this.property.listing.photos[2]);
            }
        }
    },
    methods: {
        collectOnlineCheckin() {
            this.errors = {};
            const minFields = ["telephone", "guest_name", "countryCode"];
            if (this.$route.params.booking_pid) {
                minFields.push("date_start");
                minFields.push("date_end");
            }
            let error = 0;
            let errorfields = {};
            const data = {};
            for (const f of minFields) {
                console.log("f", f, this.rData[f]);
                if (!this.rData[f] || !this.rData[f].length) {
                    error = 1;
                    errorfields[f] = 1;
                    this.errors[f] = true;
                }
            }
            if (error) {
                //error
                console.log("data missing", errorfields);
                return;
            }
            data.telephone = "" + this.rData.countryCode + this.rData.telephone;
            data.guest_name = this.rData.guest_name;
            data.guest_email = this.rData.guest_email;
            const body = {
                reservation_id: localStorage.reservation_id,
                token: localStorage.reservation_token,
            };
            data.guest_language = localStorage.btrLang;
            body.data = data;
            if (this.$route.params.booking_pid) {
                data.date_start = this.rData.date_start;
                data.date_end = this.rData.date_end;
                data.bookingId = this.$route.params.booking_pid;
                this.api.get("reservations/booking/?" + new URLSearchParams(data)).then((res) => {
                    if (res.found) {
                        this.$router.push(`/${res.reservation.reservation_id}/${res.reservation.short_token}/?found=1`);
                    }
                });
                return false;
            }
            this.api.put("reservations/checkin", { body: JSON.stringify(body) }).then((res) => {
                console.log(res);
                if (!res.success) {
                    if (res.errorMessage == "wrong_telephone") {
                        this.errors = {};
                        this.errors[res.errorMessage] = res.suggestion;
                    }
                } else {
                    this.$router.push("/online-checkin");
                }
            });
        },
        getCountryCodes() {
            this.api.get("hostfiles/countries.json").then((res) => {
                this.countryCodes = res;
            });
        },
        getGuestName() {
            if (this.reservation.guest_name.indexOf("Guest") !== -1) {
                return null;
            } else {
                let name = this.reservation.guest_name.split(" ");
                if (name[0].length < 3 && name.length > 1) {
                    return name[0] + " " + name[1];
                }
                return name[0];
            }
        },
        getVendor(key = false) {
            const supported = { airbnb: "Airbnb", booking: "Booking", tomokit: "Tomokit" };
            if (this.reservation.vendor) {
                this.reservation.vendor = this.reservation.vendor == "manual" ? "tomokit" : this.reservation.vendor;
                if (key) {
                    return supported[this.reservation.vendor] ? this.reservation.vendor : null;
                }
                return supported[this.reservation.vendor] || " ";
            } else {
                if (key) {
                    return null;
                }
                return " ";
            }
        },
    },
};
</script>
<style>
.v-application .absolute {
    position: absolute;
}

.afterselect .v-input__slot {
    background: #fff !important;
}

.mockup {
    background: url(@/assets/ref/datainsert.jpeg);
    height: 700px;
    background-size: cover;
    padding-top: 28px;
}

.relative {
    position: relative;
}

.v-application .property-details * {
    line-height: 1.5rem;
}

.v-application h2 {
    line-height: 1.2;
}

.reservation-status {
    color: #11a6ac;
}

.lighcolor {
    color: #8b98a6;
}

.property-address {
    color: #8b98a6;
}

.reservation-id {
    color: #8b98a6;
}

.placeholder {
    background: #eee;
    background-size: cover;
}

.vendor-box {
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vendor-box img {
    height: 40%;
    display: block;
}

.airbnb-box {
    background: #fb395f;
}

.booking-box {
    background: #0c3b7c;
}

.tomokit-box {
    background: #11a6ac;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.h-3 {
    height: 3rem;
}

.w-3 {
    width: 3rem;
}

.h-4 {
    height: 4rem;
}

.w-4 {
    width: 4rem;
}

.h-8 {
    height: 8rem;
}

.h-9 {
    height: 9rem;
}

.elevated-1 {
    background: #fff;
    box-shadow: 0px 2px 12px rgba(180, 190, 200, 0.7);
    border-radius: 5px;
}

.selected {
    border: 2px solid #11a6ac;
}

.reservation-date {
    width: 50%;
}

.checkin {
    border-right: 1px solid #b2c0d0;
}

.checkin-date {
    font-weight: 700;
    color: #11a6ac;
}

.absolute.bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.language-selector div {
    cursor: pointer;
}
</style>
