<template>
    <div>
        <div v-if="reservation && reservation.reservation_id" :class="cclass">
            <v-divider></v-divider>
            <div class="py-4 reservation-id text-subtitle-2">
                <img :src="require(`@/assets/img/${vendor}-logo.svg`)" class="mr-2 mb-n1" height="20px" v-if="vendor" />{{ getVendor() }} Reservation
                {{ source != "booking" ? reservation.reservation_id : "" }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "t-footer",
    props: ["reservation", "entry", "cclass"],
    computed: {
        source() {
            return this.reservation.source || null;
        },
        vendor() {
            return this.getVendor(true);
        },
    },
    methods: {
        getVendor(key = false) {
            const supported = { airbnb: "Airbnb", booking: "Booking" };
            if (this.reservation.vendor) {
                if (key) {
                    return supported[this.reservation.vendor] ? this.reservation.vendor : null;
                }
                return supported[this.reservation.vendor] || " ";
            } else {
                if (key) {
                    return null;
                }
                return " ";
            }
        },
    },
};
</script>
