<template>
    <div>
        <div class="property-details mt-3">
            <div class="reservation-status text-subtitle-1">{{ btr("Reservation confirmed") }}</div>
            <div class="property-name text-h6">{{ property.name }}</div>
            <div class="property-address text-subtitle-2 mt-1">{{ property.address }} {{ property.city }}</div>
        </div>
        <div class="reservation-visual mt-4">
            <v-row>
                <v-col cols="9">
                    <div class="placeholder h-9 rounded-lg" :style="`background-image: url('${headerImage}')`"></div>
                </v-col>
                <v-col cols="3">
                    <div v-if="vendor" :class="`vendor-box h-4 ${vendor}-box rounded-lg mb-4`">
                        <img :src="require(`@/assets/img/${vendor}-vector.svg`)" alt="" />
                    </div>
                    <div v-if="secondaryImages.length">
                        <div v-for="(image, i) in secondaryImages" :key="i" class="placeholder h-4 rounded-lg mb-4" :style="`background-image: url('${image.thumbnail}')`"></div>
                    </div>
                    <div v-else class="placeholder h-4 rounded-lg mt-4 mb-4"></div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    props: ["reservation", "entry"],
    data() {
        return {
            headerImage: null,
            property: null,
            secondaryImages: [],
        };
    },
    computed: {
        source() {
            return this.reservation.source || null;
        },
        vendor() {
            return this.getVendor(true);
        },
    },
    created() {
        this.headerImage = this.entry.cover_img || null;

        this.property = this.entry.property;
        if (this.property) {
            if (this.property.listing && this.property.listing.photos) {
                this.secondaryImages.push(this.property.listing.photos[1]);
                if (!this.vendor) {
                    this.secondaryImages.push(this.property.listing.photos[2]);
                }
            }
            if (this.property.images && this.property.images.length) {
                console.log(this.property.images[0])
                this.secondaryImages.push({ thumbnail: this.property.images[0] });
            }
        }
    },
    methods: {
        getVendor(key = false) {
            const supported = { airbnb: "Airbnb", booking: "Booking", tomokit: "Tomokit" };
            if (this.reservation.vendor) {
                this.reservation.vendor = this.reservation.vendor == "manual" ? "tomokit" : this.reservation.vendor;
                if (key) {
                    return supported[this.reservation.vendor] ? this.reservation.vendor : null;
                }
                return supported[this.reservation.vendor] || " ";
            } else {
                if (key) {
                    return null;
                }
                return " ";
            }
        },
    },
};
</script>
