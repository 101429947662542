<template>
    <div>
        <template v-if="typeof newValue == 'string' && !hideInput">
            <div class="d-flex">
                <v-text-field :label="label || 'File'" outlined v-model="newValue" readonly @click="hideInput = true" :placeholder="placeholder" :error="error"> </v-text-field
                ><v-btn
                    class="ml-n10 mt-3"
                    icon
                    @click.stop="
                        newValue = null;
                        hideInput = true;
                    "
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </div>
        </template>
        <template v-else>
            <v-file-input
                prepend-inner-icon="mdi-image"
                :label="label || 'File'"
                outlined
                v-model="fileValue"
                maxlength="1"
                @change="uploadFile"
                :loading="loading"
                :placeholder="placeholder"
                :error="error"
            >
            </v-file-input>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        label: { default: null },
        value: { default: null },
        placeholder: null,
        uploadurl: null,
    },
    data() {
        return {
            hideInput: false,
            loading: false,
            newValue: null,
            error: false,
            fileValue: null,
            u_url: "hosting/uploads/image",
        };
    },
    watch: {
        value(v) {
            this.newValue = this.value;
        },
        newValue(n) {
            this.$emit("input", n);
        },
    },
    mounted() {
        if (this.uploadurl) {
            this.u_url = this.uploadurl;
        }
        if (this.value) {
            this.newValue = this.value;
        }
    },
    methods: {
        uploadFile() {
            if (!this.fileValue) {
                return;
            }
            this.loading = true;
            this.api.upload(this.u_url, [{ fieldname: this.fieldname || "file", file: this.fileValue }]).then((res) => {
                console.log("image saved");
                const f = res.data.files["file"];
                this.newValue = f.url;
                this.hideInput = false;
                this.loading = false;
                this.$emit("saved", f.url);
            });
        },
    },
};
</script>
