<template>
    <div>
        <v-row>
            <v-col class="text-center text-h6">
                <p>{{ this.btr("To prepare your check-in I need you to answer some questions") }}</p>
                <v-btn to="/online-checkin" depressed x-large color="#40929d" class="white--text">{{ this.btr("Ok, let's do this!") }}</v-btn>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        overlay: false,
    }),
};
</script>
