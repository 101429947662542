<template>
    <div>
        <div v-if="msg.user.user_nicename == this.me" class="chat__mymessage" :class="[isSame ? '' : 'chat__first']">
            <!-- <p class="chat__yourmessage__time">23:38</p> -->
            <p class="chat__mymessage__paragraph">{{ msg.message_body }}</p>
        </div>
        <div v-else class="chat__yourmessage" :class="[isSame ? '' : 'chat__first']">
            <div class="chat__yourmessage__avatar">
                <img :src="avatar" alt="" v-if="!isSame" class="chat__yourmessage__img" />
            </div>
            <div style="flex-grow: 2;">
                <p class="chat__yourmessage__user" v-if="!isSame">
                    {{ msg.user.name }}
                </p>
                <div class="chat__yourmessage__p">
                    <div class="chat__yourmessage__paragraph">
                        <div v-for="(row, index) in msg.message_body.split('\n')" :key="index">
                            {{ row }}
                        </div>
                    </div>                    
                    <!-- <p class="chat__yourmessage__time">23:38</p> -->
                </div>
            </div>
        </div>
        <div v-if="isadmin && msg.info" class="chat__yourmessage m_info">
            <div class="chat__yourmessage__p">
                <div class="chat__yourmessage__paragraph">
                    <span v-for="(info, i) in msg.info" :key="i" class="d-block">
                        <strong class="mr-2">{{ i }}</strong> <span v-html="info"></span>
                    </span>
                </div>
                <!-- <p class="chat__yourmessage__time">23:38</p> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg", "prev", "isadmin"],
    data() {
        return {
            me: null,
            isSame: false,
            avatar: require("../assets/avatar.svg"),
        };
    },
    methods: {
        isSamePerson(msg, prev) {
            if (prev === null) {
                return false;
            } else if (prev[0] && prev[0].user.user_nicename == msg.user.user_nicename) {
                return true;
            } else {
                return false;
            }
        },
    },
    created() {
        this.me = this.$store.state.me;
        this.isSame = this.isSamePerson(this.msg, this.prev);
        if (this.msg.user.profileImage) {
            this.avatar = this.msg.user.profileImage;
        }
    },
};
</script>

<style>
.chat__mymessage {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    margin: 0;
    min-height: 40px;
}

.v-application div .chat__mymessage__paragraph {
    margin: 0.4rem 0 0 1rem;
    border-radius: 20px 20px 0px 20px;
    max-width: 320px;
    background-color: #40929d;
    color: #ffffff;
    padding: 0.8rem;
    font-size: 14px;
}

.chat__first {
    margin-top: 2rem;
}

.chat__yourmessage {
    display: flex;
}

.chat__yourmessage__avatar {
    width: 40px;
    margin-right: 1rem;
}

.chat__yourmessage__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.chat__yourmessage__user {
    font-size: 14px;
    font-weight: 700;
    color: #292929;
    margin-top: 0;
    margin-block-end: 0rem;
}

.chat__yourmessage__p {
    
}

.chat__yourmessage__paragraph {
    margin: 0.4rem 1rem 0 0;
    border-radius: 0px 20px 20px 20px;
    background-color: #f3f3f3;
    color: #414141;
    padding: 0.8rem;
    font-size: 14px;
}

.chat__yourmessage__time {
    margin: 0;
    font-size: 12px;
    color: #9c9c9c;
}
.m_info .chat__yourmessage__paragraph {
    background: #fafafa;
    color: #9c9c9c;
}
</style>
