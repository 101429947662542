class Guide {
    static init(data = { baseUrl: '', guideCategories: [] }) {
        for (const k in data) {
            Guide[k] = data[k];
        }
        if (data.baseUrl) {
            Guide.initialized = true;
        }
    }
    static getIconPath(iconNameWithExtension) {
        return Guide.baseUrl + "content/assets/app-assets/guides/img/" + iconNameWithExtension;
    }
    static getIcon(item, dark = false) {
        if (typeof item == 'string') {
            if (item.includes('.')) {
                return Guide.getIconPath(item);
            }
            item = Guide.guideCategories.find(e => e.value == item);
        }
        const iconname = item.icon || item.value;
        if (iconname) {
            return Guide.baseUrl + "content/assets/app-assets/guides/img/icon-" + iconname + (dark ? "-dark" : "") + ".svg";
        }
    }
}
Guide.init();
export default Guide;