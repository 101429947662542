import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        reservation: {},
        reservationData: {},
        headerContent: {},
        coverBg: {},
        coverImg: "",
        btrLang: "en",
        checkinTime: {},
        reservationDataRefreshed: false,
        currentGuide: null,
        appUrl: {},
        languageDialog: false,
        chatDrawer: false,
        currentInfo: null,
    },
    mutations: {},
    actions: {},
});
