<template>
    <div>
        <v-container class="px-6">
            <reservation-visual :reservation="reservation" :entry="entry"></reservation-visual>
        </v-container>
        <v-container fluid>
            <online-checkin :reservation="reservation" :entry="entry" @reservationUpdate="reservationUpdate"></online-checkin>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import ReservationVisual from "@/components/ReservationVisual.vue";
import PageCover from "@/components/PageCover";
import GFooter from "@/components/GFooter";
import OnlineCheckin from "@/components/DocumentForm";
export default {
    name: "home",
    components: {
        "reservation-visual": ReservationVisual,
        "page-cover": PageCover,
        "online-checkin": OnlineCheckin,
        "g-footer": GFooter,
        headerImage: null,
        headerTitle: null,
    },
    props: ["reservation", "entry"],
    data: () => ({
        onlineCheckinCompleted: false,
        property: null,
        headerImage: null,
        headerTitle: null,
    }),
    created() {
        this.headerImage = this.entry.cover_img || null;
        this.headerTitle = this.entry.header_content || null;
        this.property = this.entry.property;
    },
    methods: {
        reservationUpdate(r) {
            this.$emit("reservationUpdate", r);
        },
    },
};
</script>
