<template>
    <div>
        <page-cover></page-cover>
        <v-container fluid>
            <v-row align="start" justify="center">
                <v-col class="text-center">
                    <h4>Tra 7 giorni potrai eseguire il self check-in direttamente da questa pagina.</h4>
                    <p>Nel frattempo, ho preparato delle informazioni che potrebbero esserti utili</p>
                </v-col>
            </v-row>
            <v-row align="start" justify="center">
                <v-list two-line subheader class="guide-list">
                    <v-list-item>
                        <v-list-item-avatar tile>
                            <img src="../assets/img/icon-hand-key.svg" class="no-border-radius" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle>Come funziona il</v-list-item-subtitle>
                            <v-list-item-title>Self Check-in</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-avatar tile>
                            <img src="../assets/img/icon-sign.svg" class="no-border-radius" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle>Informazioni su</v-list-item-subtitle>
                            <v-list-item-title>Come arrivare</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-avatar tile>
                            <img src="../assets/img/icon-parking.svg" class="no-border-radius" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle>Informazioni su</v-list-item-subtitle>
                            <v-list-item-title>Come parcheggiare</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-row>
            <chat-banner></chat-banner>
            <online-checkin-prompt></online-checkin-prompt>
            <online-checkin></online-checkin>
            <v-row>
                <v-col>
                    <v-stepper v-model="e1">
                        <v-stepper-header style="display: none">
                            <v-stepper-step :complete="e1 > 1" step="1" color="#40929d">Name of step 1</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step :complete="e1 > 2" step="2" color="#40929d">Name of step 2</v-stepper-step>

                            <v-divider></v-divider>

                            <v-stepper-step step="3" color="#40929d">Name of step 3</v-stepper-step>
                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col class="text-center">
                                        <p>Per prima cosa vai al seguente indirizzo:</p>
                                        <v-list-item-avatar tile size="128">
                                            <img src="../assets/img/icon-pin-map.svg" class="no-border-radius" />
                                        </v-list-item-avatar>
                                        <h3>Lungomare Alfeo, 110</h3>
                                        <h3>96100 Siracusa</h3>
                                        <div>
                                            <v-btn color="#40929d" text>Apri su google maps</v-btn>
                                        </div>
                                        <div>
                                            <v-btn color="#40929d" x-large class="white--text" @click="e1 = 2">Sono già qui</v-btn>
                                        </div>
                                        <div>
                                            <v-btn color="#40929d" text class="lowercase--text">Non trovo l'indirizzo</v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row justify="center">
                                    <v-col class="text-center">
                                        <p>Benissimo, dovresti essere davanti a questo portone.</p>
                                        <img v-on:dblclick="overlay = true" src="../assets/content-img/maxresdefault.jpg" style="max-width: 500px" />
                                        <h3>Clicca due volte sulla foto per aprire</h3>
                                        <v-overlay :value="overlay">
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                        </v-overlay>
                                        <r-div justify="center">
                                            <v-alert type="warning" max-width="500" outlined>Per favore, apri solo se ti trovi davanti alla porta. Altrimenti la porta resterà aperta!</v-alert>
                                        </r-div>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-row>
                                    <v-col class="text-center">
                                        <p>La porta si è aperta?</p>
                                        <div>
                                            <v-btn color="#40929d" x-large class="white--text" @click="e1 = e1 + 1">Sì, è aperta!</v-btn>
                                        </div>
                                        <br />
                                        <div>
                                            <v-btn color="#40929d" x-large outlined @click="e1 = e1 - 1">No, riprova</v-btn>
                                        </div>
                                        <br />
                                        <p>Se qualcosa non funziona o la porta non si apre dopo un altro tentativo, contattami!</p>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                    <g-footer></g-footer>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import RDiv from "../components/RDiv";
import PageCover from "../components/PageCover";
import OnlineCheckinPrompt from "@/components/OnlineCheckinPrompt";
import GFooter from "@/components/GFooter";
import OnlineCheckin from "@/components/OnlineCheckin";
import ChatBanner from "@/components/ChatBanner";
export default {
    components: {
        "r-div": RDiv,
        "page-cover": PageCover,
        "online-checkin-prompt": OnlineCheckinPrompt,
        "g-footer": GFooter,
        "online-checkin": OnlineCheckin,
        "chat-banner": ChatBanner,
    },
    props: {
        source: String,
    },
    computed: {
        getGuests: function () {
            var adultString = this.adults > 1 ? " adulti, " : " adulto, ";
            var babyString = this.babies > 1 ? " bambini" : " bambino";
            return this.adults + adultString + this.babies + babyString;
        },
    },
    data: () => ({
        drawer: false,
        e6: 4,
        e1: 0,
        radios: "radio-1",
        arrivalPretext: ["Dopo le", "Prima delle"],
        arrivalPretextVal: "Dopo le",
        adults: 1,
        babies: 0,
        babyYears: ["1 anno"],
        overlay: false,
    }),
    watch: {
        overlay(val) {
            val &&
                setTimeout(() => {
                    this.overlay = false;
                    this.e1 = this.e1 + 1;
                }, 3000);
        },
    },
    mounted: function () {
        for (var i = 2; i < 18; i++) {
            this.babyYears.push(i + " anni");
        }
    },
};
</script>

<style>
.header-content {
    max-width: 1080px;
    margin: 0 7%;
    color: #fff;
    margin-top: 140px;
    margin-top: 50px;
    text-align: center;
    font-size: 24px;
    line-height: 130%;
    z-index: 2;
}
.page-header {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 0;
}
.page-header::after {
    content: "";
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
}
.page-header .page-cover {
    content: "";
    opacity: 0.6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    /* filter: blur(2px) brightness(110%) saturate(80%); */
}
.page-header .header-content {
    padding: 70px 0;
    margin: 0 7%;
    margin: 0 auto;
}
.brand-header {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.brand-header.with-cover {
    min-height: 300px;
}
.brand-header::after {
    content: "";
    background: #559ba5;
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
}
.brand-header .brand-cover {
    content: "";
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}
.no-border-radius {
    border-radius: 0;
}
.rounded img {
    border-radius: 200px !important;
}
.pbar {
    margin: 40px 0;
    background: #40929d;
    color: #fff;
}
.pbar.full-width {
    width: 100%;
}
.pbar-content {
    padding: 20px;
}
.pbar .v-avatar {
    position: relative;
    margin-top: -32px;
}
</style>
