export default {
    methods: {
        toFormData(obj) {
            var f = new FormData();
            for (var i in obj) {
                if (obj.length < 1 || obj[i] != undefined) {
                    f.append(i, obj[i]);
                }
            }
            return f;
        }
    }
}