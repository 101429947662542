<template>
    <div class="page-header with-cover mb-4">
        <div class="page-cover" :style="coverBg"></div>
        <div class="header-content px-2" v-html="headerContent"></div>
    </div>
</template>
<script>
export default {
    props: ["coverBg", "headerContent"],
    data: () => ({}),
};
</script>
