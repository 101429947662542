<template>
    <div>
        <div id="container">
            <v-stepper v-model="currentStep" class="elevation-0" v-if="!this.checkinDisabled"><v-stepper-items>
                    <v-stepper-content :step="-1">
                        <div class="text-center pb-4">
                            <div class="my-4">
                                <img src="../assets/img/checking-in.svg" />
                            </div>
                            <h4 class="mb-4 mt-8 text-h5 font-weight-bold">{{ btr("Do you want to start checking in?") }}</h4>
                            <div v-html="btr('Self check-in will be available on %s', getReservationTime('day'))"></div>
                            <div v-html="btr(' starting at %s.', getReservationTime('time'))"></div>
                            <div v-html="btr('If you want to see a preview of how self check-in works, you can give it a try')"></div>
                            <div class="mt-8 mb-8">
                                <v-btn block color="primary" x-large elevation="0" class="mb-4" @click="
                simulation = true;
            currentStep = 1;
            ">{{ btr("Give it a try") }}</v-btn>
                                <v-btn block color="primary" x-large outlined elevation="0" to="/">{{ btr("Go back") }}</v-btn>
                            </div>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content :step="0">
                        <div class="text-center pb-4">
                            <div class="my-4">
                                <img src="../assets/img/checking-in.svg" />
                            </div>
                            <h4 class="mb-4 mt-8 text-h5 font-weight-bold">{{ btr("Do you want to start checking in?") }}</h4>
                            <p class="text-h6 font-weight-regular" v-html="btr('For security reasons, you will only be able to check in <strong>only once</strong>. Begin check-in only if you want to enter now.')"></p>
                            <div class="mt-8 mb-8">
                                <v-btn block color="primary" x-large elevation="0" class="mb-4" @click="
                simulation = false;
            changeStep(1, true);
            ">{{ btr("Start check-in") }}</v-btn>
                                <v-btn block color="primary" x-large outlined elevation="0" @click="
                simulation = true;
            changeStep(1, true);
            ">{{ btr("Just give it a try") }}</v-btn>
                            </div>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content :step="index" v-for="(step, index) in steps" :key="index">
                        <div class="text-center pb-4">
                            <h4 class="mb-2 mt-2 text-h4 font-weight-bold">{{ step.name }}</h4>
                            <div class="text-center mb-4" v-if="step.type == 'ringIntercom' && !step.image">
                                <img src="../assets/img/icon-fingerIntercom.svg" class="w-30" />
                            </div>
                            <div v-if="!stepUtility[currentStep] || (stepUtility[currentStep] && !stepUtility[currentStep].smartlockphase2 && !stepUtility[currentStep].secondary_phase)">
                                <p v-if="step.description_type != 'rich_text'">
                                    {{ step.description }}
                                </p>
                                <div v-else v-html="step.description" class="step__description"></div>
                                <div v-if="step.image" class="my-4 text-center">
                                    <img :src="step.image" style="max-width: 85%" class="rounded-lg" />
                                </div>
                                <div v-else-if="step.type == 'checkinEnd' && propertyHeaderImage" class="my-8 text-center">
                                    <img :src="propertyHeaderImage" style="max-width: 85%" class="rounded-lg" />
                                </div>
                            </div>
                            <!-- CHECK POSITION -->
                            <div v-if="step.type == 'checkPosition'">
                                <div v-if="step.question_type == 'multiple_answers'">
                                    <p class="mt-2">{{ step.verification_question }}</p>
                                    <v-select label="seleziona una risposta" class="mt-2" v-model="step.user_answer" outlined :items="step.verification_answers"></v-select>
                                    <v-btn v-if="step.user_answer != null" class="elevation-0" block x-large color="primary">{{ btr("verify") }}</v-btn>
                                    <v-btn v-else disabled class="elevation-0" block x-large color="primary">{{ btr("verify") }}</v-btn>
                                </div>
                                <div v-if="step.question_type == 'verfication_code'">
                                    <p class="mt-2">{{ step.instructions }}</p>
                                    <v-row>
                                        <v-col>
                                            <div class="display-1 mt-3 text-right">
                                                {{ step.suggestion }}
                                            </div>
                                        </v-col>
                                        <v-col>
                                            <v-text-field class="display-1 mr-1" v-model="step.user_answer"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn v-if="step.user_answer == step.verification_answer" class="elevation-0" block x-large color="primary">{{ btr("confirm") }}</v-btn>
                                    <v-btn v-else disabled class="elevation-0" block x-large color="primary">{{ btr("confirm") }}</v-btn>
                                </div>
                            </div>
                            <!-- GO TO ADDRESS -->
                            <div v-if="step.step_delay && loading">
                                <div>
                                    {{ btr("Please wait... %s seconds...", step.step_delay) }}
                                </div>
                                <v-progress-circular class="my-4" :indeterminate="loading" color="primary" rounded></v-progress-circular>
                                <div class="mt-2" v-if="step.step_delay_description" v-html="step.step_delay_description"></div>
                            </div>
                            <div v-if="step.type == 'goToAddress'">
                                <v-list-item-avatar tile size="128" v-if="!step.image">
                                    <a style="display: inline-block; width: 128px; height: 128px" rel="noreferrer" target="_blank" :href="step.maps_link ? step.maps_link : 'https://www.google.com/maps?f=q&source=s_q&q=' + step.address + ' ' + step.city">
                                        <img src="../assets/img/icon-pin-map.svg" class="no-border-radius" />
                                    </a>
                                </v-list-item-avatar>
                                <h3>{{ step.address }}</h3>
                                <h3>{{ step.city }}</h3>
                                <div class="my-4">
                                    <v-btn color="primary" text rel="noreferrer" target="_blank" :href="step.maps_link ? step.maps_link : 'https://www.google.com/maps?f=q&source=s_q&q=' + step.address + ' ' + step.city">{{ btr("Open with google maps") }}</v-btn>
                                </div>
                                <div>
                                    <v-btn color="primary" x-large class="white--text elevation-0" @click="step.autoUnlock ? lockVerified('next', step) : changeStep(1)">{{
                btr("I'm already here")
            }}</v-btn>
                                </div>
                            </div>
                            <!-- RING INTERCOM -->
                            <div v-if="step.type == 'ringIntercom'">
                                <v-progress-circular class="my-4" v-if="loading" :indeterminate="loading" color="primary" rounded></v-progress-circular>
                                <div v-if="(!stepUtility[currentStep].secondary_phase || !step.autoUnlock) && (stepUtility[currentStep] && !stepUtility[currentStep].smartlockphase2)">
                                    <div class="my-4">
                                        <div class="safebox-code display-1 py-4 px-2 rounded" style="letter-spacing: 2px !important; background: #efefef">{{ step.lock_code }}</div>
                                    </div>
                                    <v-btn v-if="step.autoUnlock" color="#40929d" x-large class="white--text mt-4 elevation-0" @click="
                stepUtility[currentStep].secondary_phase = 2;
            setPhase('secondary', 2);
            ">{{ btr("Ok, done") }}</v-btn>
                                    <v-btn v-else color="#40929d" x-large class="white--text mt-4 elevation-0" @click="changeStep(1)">{{ btr("Ok, done") }}</v-btn>
                                </div>
                                <div v-if="stepUtility[currentStep]['secondary_phase'] == 2">
                                    <p>{{ btr("Did you ring the bell?") }}</p>
                                    <div>
                                        <v-btn color="primary" x-large elevation="0" @click="
                loading = true;
            $set(stepUtility[currentStep], 'secondary_phase', -1);
            lockVerified('next', step);
            ">{{ btr("Yes, I did!") }}</v-btn>
                                    </div>
                                    <br />
                                    <div>
                                        <v-btn color="primary" x-large outlined elevation="0" @click="$set(stepUtility[currentStep], 'secondary_phase', 0)">{{ btr("No, try again") }}</v-btn>
                                    </div>
                                    <br />
                                    <p>
                                        {{ btr("If something does not work or the door does not open after another attempt, contact me!") }}
                                    </p>
                                </div>
                            </div>
                            <!-- OPEN SAFEBOX -->
                            <div v-if="step.type == 'openSafebox'">
                                <div v-if="step.step_phase == 'phase_code' || step.step_phase == 'phase_shuffle'">
                                    <div v-if="step.safebox">
                                        <img :src="require('@/assets/img/safeboxes/safebox-' + step.safebox.icon + '-close.jpg')" class="g-icon mt-4" style="width: auto; height: auto; max-width: 200px" />
                                        <p class="mt-4">{{ step.phase_description }}</p>
                                    </div>
                                    <div v-if="showCodes" class="safebox-code mt-4 display-1" style="letter-spacing: 4px !important">
                                        {{ step.lock_code }}
                                    </div>
                                    <div v-else>
                                        <div class="safebox-code mt-4 display-1" style="letter-spacing: 4px !important">****</div>
                                        <div class="caption mb-2">({{ btr("the code will be shown during the actual check-in") }})</div>
                                    </div>
                                    <v-btn color="primary" x-large class="mt-4 elevation-0" @click="changeStep(1)">{{ btr("Ok, done") }}</v-btn><br />
                                    <v-btn color="primary" outlined x-large class="mt-4 elevation-0" @click="changeStep(-1)">{{ btr("Back") }}</v-btn>
                                </div>
                                <div v-if="step.step_phase == 'phase_open'">
                                    <div v-if="step.safebox">
                                        <img :src="require('@/assets/img/safeboxes/safebox-' + step.safebox.icon + '-open.jpg')" class="g-icon mt-4" style="width: auto; height: auto; max-width: 200px" />
                                        <p class="mt-4">{{ step.phase_description }}</p>
                                    </div>
                                    <v-btn color="primary" x-large class="mt-4 elevation-0" @click="changeStep(1)">{{ btr("Ok, done") }}</v-btn><br />
                                    <v-btn color="primary" outlined x-large class="mt-4 elevation-0" @click="changeStep(-1)">{{ btr("Back") }}</v-btn>
                                </div>
                            </div>
                            <div v-if="stepUtility[currentStep] && stepUtility[currentStep].smartlockphase2">
                                <p>{{ btr("Did the door open?") }}</p>
                                <div>
                                    <v-btn color="primary" x-large elevation="0" @click="changeStep(1)">{{ btr("Yes, it's open!") }}</v-btn>
                                </div>
                                <br />
                                <div>
                                    <v-btn color="primary" x-large outlined elevation="0" @click="lockVerified('back', step)">{{ btr("No, try again") }}</v-btn>
                                </div>
                                <br />
                                <p>
                                    {{ btr("If something does not work or the door does not open after another attempt, contact me!") }}
                                </p>
                            </div>
                            <!-- OPEN SMART LOCK -->
                            <div v-if="step.type == 'openLock'">
                                <div v-if="step.lock_type == 'lock_keyboard'">
                                    <div v-if="showCodes" class="safebox-code mt-4 display-1" style="letter-spacing: 4px !important">
                                        {{ step.lock_code }}
                                    </div>
                                    <div v-else>
                                        <div class="safebox-code mt-4 display-1" style="letter-spacing: 4px !important">****</div>
                                        <div class="caption mb-2">({{ btr("the code will be shown during the actual check-in") }})</div>
                                    </div>
                                    <v-btn color="primary" x-large class="mt-4 elevation-0" @click="changeStep(1)">{{ btr("Ok, done") }}</v-btn>
                                </div>
                                <div v-if="step.lock_type == 'lock_smart'">
                                    <v-progress-circular class="my-4" v-if="loading" :indeterminate="loading" color="primary" rounded></v-progress-circular>
                                    <v-row justify="center" v-if="step.lock_type == 'knock_knock'">
                                        <v-col class="text-center">
                                            <p>
                                                {{ btr("Great, you should be in front of this front door.") }}
                                            </p>
                                            <img v-on:dblclick="overlay = true" src style="max-width: 500px" />
                                            <h3>{{ btr("Double click on the photo to open") }}</h3>
                                            <v-overlay :value="overlay">
                                                <v-progress-circular indeterminate size="64"></v-progress-circular>
                                            </v-overlay>
                                            <v-alert type="warning" max-width="500" outlined>{{
                btr("Please only open if you are in front of the door. Otherwise, the door will stay open!")
            }}</v-alert>
                                        </v-col>
                                    </v-row>
                                    <div v-else>
                                        <div v-if="!stepUtility[currentStep] || !stepUtility[currentStep].smartlockphase2">
                                            <div v-if="!step.interaction_type || step.interaction_type == 'slide'">
                                                <drag-verify v-if="!loading" text="&rarr; &rarr; &rarr; &rarr; &rarr;" successText="Apertura in corso" :handler-icon="require('@/assets/img/icon-lock.svg')" :success-icon="require('@/assets/img/icon-unlock.svg')" handler-bg="#42929D" progressBarBg="#42929D" completedBg="#42929D" background="#ECEEF0" text-size="14px" :width="280" class="mx-auto mt-4" @swiped="lockVerified('next', step)"></drag-verify>
                                                <p class="mt-4">
                                                    {{ btr("Drag the circle to the right to open") }}
                                                </p>
                                            </div>

                                            <div v-if="step.interaction_type == 'button'">
                                                <v-btn class="mt-8 elevation-0" x-large depressed color="primary" @click="lockVerified('next', step)">{{ btr("Press to open") }}</v-btn>
                                                <p class="mt-8">{{ btr("Press the button to open") }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="step.lock_type == 'lock_keyboard'"></div>
                                <div v-if="step.lock_type == 'lock_normal'">
                                    <v-btn color="primary" x-large class="mt-4 elevation-0">{{ btr("Okay, I'm in") }}</v-btn>
                                </div>
                            </div>
                            <div class="mt-8 mb-8" v-if="!simulation && showChatButton">
                                <button class="brand-btn pulsing" elevation="0" x-large color="primary" @click="openChat()">{{ btr("Do you need help?") }}</button>
                            </div>
                            <div v-if="step.step_delay && loading"></div>
                            <div v-else>
                                <v-btn v-if="['generic'].includes(step.type)" color="primary" x-large class="mt-4 elevation-0" @click="step.autoUnlock ? lockVerified('next', step) : changeStep(1)">{{
                btr("Ok, next")
            }}</v-btn>
                                <v-btn block v-if="['checkinEnd'].includes(step.type)" color="primary" x-large class="mt-4 elevation-0" @click="closeCheckin">{{ btr("End") }}</v-btn>
                            </div>
                            <div v-if="step.more_info" class="mt-2">
                                <v-btn color="#40929d" text class="lowercase--text elevation-0">{{ step.more_info_question }}</v-btn>
                            </div>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import dragVerify from "./dragVerify.vue";
import { format, parseISO } from "date-fns";
import { it } from 'date-fns/locale'
export default {
    components: {
        "drag-verify": dragVerify,
    },
    props: ["steps", "currentCheckinStep", "isSimulation", "checkinDisabled", "reservation", "isHosting", "propertyHeaderImage", 'property'],
    data() {
        return {
            secToShowChat: 14,
            chatTimer: null,
            showChatButton: false,
            loading: false,
            overlay: false,
            currentStep: 1,
            stepUtility: { 1: {} },
            simulation: false,
            showCodes: true,
        };
    },
    watch: {
        currentCheckinStep(s) {
            this.currentStep = s;
        },
        steps(steps) {
            let ks = Object.keys(this.steps);
            let vs = Object.values(this.steps);
            for (let index = 0; index < vs.length; index++) {
                this.stepUtility[ks[index]] = this.stepUtility[ks[index]] || { secondary_phase: 0 };
            }
        },
    },
    mounted() {
        console.log("propertyH", this.propertyHeaderImage);
        this.simulation = this.isSimulation;
        if (this.simulation && !this.isHosting) {
            this.showCodes = false;
        }
        if (this.currentCheckinStep) {
            this.currentStep = this.currentCheckinStep;
        } else {
            this.currentStep = 0;
            this.$nextTick(function () {
                this.currentStep += 1;
            });
        }
        if (this.steps[this.currentStep]) {
            this.headerTitle = this.steps[this.currentStep].name;
        }
        let ks = Object.keys(this.steps);
        let vs = Object.values(this.steps);
        console.log("vs", vs);
        const steputil = {};
        for (let index = 0; index < vs.length; index++) {
            console.log("...");
            steputil[ks[index]] = { secondary_phase: 0 };
        }
        this.$set(this, "stepUtility", steputil);
        this.chatTimer = setTimeout(() => {
            this.showChatButton = true;
        }, this.secToShowChat * 1000);
    },
    methods: {
        getReservationTime(what) {
            if (what == "time") {
                if (this.reservation) {
                    return this.reservation.checkin_time
                }
                if (this.property) {
                    return this.property.checkin_time;
                }
                return '';
            }
            if (!this.reservation) {
                return ''
            }
            const date = parseISO(this.reservation.date_start + " " + (this.reservation.checkin_time || this.property.checkin_time));
            const options = {};
            if (localStorage.btrLang == "it") {
                options.locale = it;
            }
            if (what == "day") {
                return format(date, 'd MMMM', options);
            }
        },
        openChat() {
            this.$store.state.currentiInfo = { step: this.currentStep, name: this.steps[this.currentStep] ? this.steps[this.currentStep].name : '', description: this.steps[this.currentStep].description };
            this.$store.state.chatDrawer = true;
        },
        closeCheckin() {
            if (this.simulation) {
                //return true;
            }
            this.$router.push("/?reload=1");
        },
        changeStepHandler(n) {
            this.currentStep = +this.currentStep + n;
            const step = this.steps[this.currentStep];
            let chatDelay = this.secToShowChat * 1000;
            if (step.delayChatButton && step.delayChatButtonSec) {
                chatDelay = step.delayChatButtonSec * 1000;
            }
            console.log("delay", chatDelay);
            clearTimeout(this.chatTimer);
            this.chatTimer = setTimeout(() => {
                this.showChatButton = true;
            }, chatDelay);
            console.log(this.currentStep, step);
            if (step) {
                this.headerTitle = step.name;
            }

            this.$emit("stepChange", this.currentStep);
            if (!this.simulation) {
                const status = { section: "checkin", step: this.currentStep, stepTitle: step ? step.name : '' };
                this.api.post("reservationguestatus/" + this.reservation.reservation_id, { data: status }).then((res) => {
                    console.log(res);
                });
            }
            if (step.type == "checkinEnd" || n == this.steps.length - 1) {
                if (this.simulation) {
                    return true;
                }
                this.api.put("checkin/" + this.reservation.reservation_id + "/checkincomplete").then((res) => {
                    console.log(res);
                });
            }
        },
        changeStep(n, force = false) {
            if (this.currentStep == 0 && force) {
                if (this.isHosting) {
                    this.simulation = true;
                }
                if (this.simulation && !this.isHosting) {
                    this.showCodes = false;
                }
                this.currentStep = 1;
            }
            if (this.currentStep == 1 && !force && !this.isSimulation) {
                this.stepUtility[0] = {};
                this.currentStep = 0;
                return;
            }
            const step = this.steps[this.currentStep];
            this.showChatButton = false;
            clearTimeout(this.chatTimer);
            if (step.step_delay) {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.changeStepHandler(n);
                }, +step.step_delay * 1000);
                return;
            }
            this.changeStepHandler(n);
        },
        setPhase(id, n) {
            console.log("current phase " + id, this.stepUtility[this.currentStep][id + "_phase"]);
            this.$set(this.stepUtility[this.currentStep], id + "_phase", +n);
            this.$set(this.stepUtility, this.currentStep, this.stepUtility[this.currentStep]);
            console.log("phase " + id + " set to: ", this.stepUtility[this.currentStep][id + "_phase"]);
        },
        lockVerifiedHandler(nextphase, loading) {
            this.loading = loading;
            console.log("here i go!", nextphase);
            this.$set(this.stepUtility[this.currentStep], "smartlockphase2", nextphase);
            this.$set(this.stepUtility[this.currentStep], "secondary_phase", nextphase);
        },
        lockVerified(direction, step) {
            if (direction == "next") {
                //var vjs = this;
                console.log("step", step);

                if (!this.simulation) {
                    const data = { access_pin: this.reservation.access_pin, unlock: 1, lock_id: step.lock_id || 0 };
                    if (step.lock_payload) {
                        data.payload = step.lock_payload;
                    }
                    this.api.post("checkin/" + this.reservation.reservation_id, { data: data }).then((res) => {
                        console.log("res");
                    });
                } else {
                    console.log("Simulating opening");
                }
                this.loading = true;
                setTimeout(
                    () => {
                        if (step.lock_type == "switch" || this.isSimulation) {
                            this.loading = false;
                            this.changeStep(1);
                        } else {
                            this.lockVerifiedHandler(true, false);
                        }
                    },
                    step && step.openDelay ? step.openDelay * 1000 : 1000
                );
            } else {
                this.lockVerifiedHandler(false, false);
            }
        },
    },
};
</script>
<style>
#container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.step__description img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
}

div.step__description p {
    margin-bottom: 0px;
}

div.step__description li {
    text-align: left;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.brand-btn {
    display: inline-block;
    padding: 1rem 1rem;
    color: #fff;
    background: #11a6ac;
    border-radius: 0.5rem;
}

.pulsing {
    box-shadow: 0 0 0 0px rgba(17, 166, 172, 0.5);
    animation: pulse 4.5s infinite;
}

.pulsing:hover {
    animation: none;
}

@keyframes pulse {
    20% {
        box-shadow: 0 0 0 50px rgba(17, 166, 172, 0);
    }

    100% {
        box-shadow: 0 0 0 50px rgba(17, 166, 172, 0);
    }
}
</style>
